import React, { useEffect, useMemo } from 'react';
import {
  Container,
  FormLabel,
  OverlayTrigger,
  Tooltip,
  FormControl,
  Button,
  TooltipProps,
  Form,
  Row,
  InputGroup,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SubscriptionSettingRequirements, SubscriptionSettings } from '../../controllers/subscription-service/types';
import { Info } from '../../icons';

export type DataCollectionAccountFrameProp = {
  dcaAccountName: string,
  settingRequirements: SubscriptionSettingRequirements[],
  dataCollectionAccountId: string,
  onSave: (formData: Record<string, string | number | string[]>) => Promise<void>,
  onDelete: () => void,
  settingData: SubscriptionSettings[],
};

export function SettingToolTip(
  props: TooltipProps & { settingDescription: string },
) {
  const { settingDescription, ...restProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...restProps}>
      {settingDescription}
    </Tooltip>
  );
}

const getSettingInputType = (is_secret: boolean, input_type: string) => {
  if (is_secret) {
    return 'password';
  }
  if (input_type.toLowerCase().startsWith('multiselect')) {
    return 'multiselect';
  }
  return input_type;
};

const convertMultiSelectToOptionsArray: (inputType: string) => string[] = (input_type: string) => {
  try {
    return JSON
      .parse(input_type.replace('multiselect', ''))
      .map((val: string) => val.trim());
  } catch (e) {
    return [];
  }
};

const defineValidationSchemaBasedOnType = (input_type: string, validation_regex: string) => {
  const parsedType = getSettingInputType(false, input_type);
  switch (parsedType) {
    case 'enum':
      return Yup.array();
    case 'date':
      return Yup.date()
        .required('This field is required');
    case 'multiselect':
      return Yup.array()
        .min(1, 'You must check at select at least one');
    case 'number':
      return Yup.number()
        .required('This field is required');
    default:
      return Yup.string()
        .matches(new RegExp(validation_regex), 'Invalid input')
        .required('This field is required');
  }
};

export default function DataCollectionAccountFrame(
  {
    dcaAccountName,
    settingRequirements,
    dataCollectionAccountId,
    onSave,
    onDelete,
    settingData,
  }: DataCollectionAccountFrameProp,
) {
  const getSettingByKey = (requirement_key: string) => settingData
    .find(({ key }) => key === requirement_key)?.value;

  const getDefaultValue = (key: string) => {
    const setting = (settingRequirements.find(
      ({ setting_key: settingKey }) => (settingKey === key),
    )) as SubscriptionSettingRequirements;

    if (getSettingInputType(false, setting.input_type) === 'multiselect') {
      try {
        const defaultValue = JSON.parse(setting.default_value) as string[];
        if (defaultValue.length > 0) {
          return defaultValue;
        }
        throw new Error(`not default array created for ${setting.input_label}`);
      } catch (e) {
        return [];
      }
    }

    return setting.default_value;
  };
  const validationSchema = useMemo(() => {
    const newSchemaTemplate = settingRequirements.reduce(
      (acc, { input_type, setting_key, validation_regex }) => (
        input_type === 'hidden'
          ? acc
          : {
            ...acc,
            [setting_key]: defineValidationSchemaBasedOnType(input_type, validation_regex),
          }),
      {},
    );

    return Yup.object().shape(
      newSchemaTemplate,
    );
  }, [settingRequirements]);

  const hasAccountNameKey = settingRequirements.some(({ setting_key }) => setting_key === 'account_name');

  const initialValues = useMemo(() => settingRequirements.reduce(
    (
      acc,
      { setting_key, input_type },
    ) => (
      input_type === 'hidden'
        ? acc
        : {
          ...acc,
          [setting_key]: getSettingByKey(setting_key) ?? getDefaultValue(setting_key),
        }),
    {
      dca_account_name: dcaAccountName,
    },
  ) as Record<string, string | number | string[]>, [settingRequirements]);

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      onSave(formik.values);
    },

    validationSchema,
    validateOnChange: true,
  });

  const isSaveDisabled = (formik.initialValues === formik.values)
    || Object.values(formik.errors).length > 0;

  useEffect(() => {
  }, [formik.values]);
  return (
    <Form
      className="mt-1 mb-4 p-2 border rounded bg-quicksights-grey"
      onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}
      noValidate
    >
      <Row>
        <p className=" small muted col-9">
          Account:
          {' '}
          {dataCollectionAccountId}
        </p>
        <Container className="d-flex col-3 p-0 justify-content-around">
          <Button
            className="col-5 p-1 bg-coral-red"
            variant="danger"
            onClick={() => { onDelete(); }}
          >
            Delete
          </Button>
          <Button
            className="col-5 p-1 mx-2 ma-btn"
            data-testid="save-setting-button"
            variant="primary"
            type="button"
            disabled={isSaveDisabled}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
        </Container>

      </Row>
      <hr className="mt-2 mb-1 color-gizmo-grey" />
      {
        !hasAccountNameKey
          ? (
            <Form.Group>
              <FormLabel htmlFor="dca_account_name">
                Account Name
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 100 }}
                  overlay={
                    (props) => SettingToolTip(
                      {
                        ...props,
                        settingDescription: 'The name of this account',
                      },
                    )
                  }
                >
                  <span className="text-a8-grey ms-2">
                    <Info fill="var(--a8-grey)" />
                  </span>
                </OverlayTrigger>
              </FormLabel>
              <FormControl
                id="dca_account_name"
                name="dca_account_name"
                value={formik.values.dca_account_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  (formik.touched.dca_account_name && ('dca_account_name' in formik.errors))
                }
                type="text"
              />
            </Form.Group>
          )
          : null
      }
      {
        settingRequirements.filter(
          (({ input_type }) => input_type !== 'hidden'),
        ).map(({
          id,
          input_label,
          setting_description,
          setting_key,
          is_secret,
          input_type,
        }) => {
          const type = getSettingInputType(is_secret, input_type);
          const isMultiSelect = type === 'multiselect';
          const options: string[] = isMultiSelect
            ? convertMultiSelectToOptionsArray(input_type)
            : [];
          const fieldId = `account-set-field-${id}-${setting_key}`;
          return (
            <Form.Group className="my-2" key={fieldId}>
              <FormLabel htmlFor={isMultiSelect ? undefined : fieldId}>
                {input_label}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 100 }}
                  overlay={
                    (props) => SettingToolTip(
                      {
                        ...props,
                        settingDescription: setting_description,
                      },
                    )
                  }
                >
                  <span className="text-a8-grey ms-2">
                    <Info fill="var(--a8-grey)" />
                  </span>
                </OverlayTrigger>
              </FormLabel>
              <InputGroup hasValidation>
                {
                  isMultiSelect
                    ? (
                      <Container>
                        {options.map((option) => (
                          <Form.Label
                            key={`${setting_key}-${option}`}
                            className="mx-2"
                          >
                            {option}
                            <Form.Check
                              name={setting_key}
                              type="switch"
                              value={option}
                              checked={
                                (formik.values[setting_key] as string[]).includes(option)
                              }
                              isInvalid={
                                (setting_key in formik.errors)
                              }
                              onChange={formik.handleChange}
                            />
                          </Form.Label>
                        ))}
                      </Container>
                    )
                    : (
                      <FormControl
                        id={fieldId}
                        name={setting_key}
                        value={formik.values[setting_key]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched[setting_key]
                          && (setting_key in formik.errors)
                        }
                        type={type}
                      />
                    )
                }
                <FormControl.Feedback
                  className={
                    formik.touched[setting_key] && !!formik.errors[setting_key]
                      ? 'd-block'
                      : ''
                  }
                  tooltip
                  type="invalid"
                >
                  {formik.errors[setting_key]}
                </FormControl.Feedback>
              </InputGroup>
            </Form.Group>
          );
        })
      }
    </Form>
  );
}
