import React, { useState, ChangeEvent } from 'react';
import {
  Container,
  Row,
  Button,
  Form,
} from 'react-bootstrap';
import { datadogRum } from '@datadog/browser-rum';
import SnackBar, { AlertTypes } from '../../components/snackBar/snackBar';
import { validateEmail } from '../../controllers/auth';
import { forgotPassword } from '../../controllers/user-service';
import './Forgot.css';

function Forgot() {
  const [userEmail, setUserEmail] = useState('');
  const [messageMap, setMessageMap] = useState({ show: false, alertType: 'danger', alertText: '' });
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    forgotPassword(userEmail)
      .then(() => {
        setMessageMap({ show: true, alertText: 'Successfully submitted forgotten password request.', alertType: 'success' });
      })
      .catch((error) => {
        datadogRum.addError(error);
        setMessageMap({ show: true, alertText: error.message, alertType: 'danger' });
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUserEmail(e.currentTarget.value);
  };

  return (
    <div id="reset-container" className="display-grid page h-100 container justify-content-center">
      <Form
        id="resetForm"
        className="align-self-center"
        onSubmit={handleSubmit}
      >
        <img className="row" src="/assets/images/Marshall_logo_insights.svg" alt="Marshall Insights" />
        <Form.Group className="mb-3">
          <Form.Control
            id="username-input"
            name="email"
            type="email"
            autoComplete="off"
            className="input-block-level"
            placeholder="Email"
            onChange={handleChange}
          />
        </Form.Group>
        <div className="forgot-area">
          <Container>
            <Row className="justify-content-md-center">
              <SnackBar
                show={messageMap.show}
                alertText={messageMap.alertText}
                alertType={messageMap.alertType as AlertTypes}
                onClose={() => setMessageMap({ show: false, alertText: '', alertType: 'danger' })}
                header={messageMap.alertType === 'danger' ? 'Error' : 'Success'}
                style={{
                  position: 'absolute', width: '25vw', zIndex: 1000,
                }}
              />
            </Row>
            <Row className="justify-content-md-center">
              <Button
                variant="primary"
                type="submit"
                disabled={!validateEmail(userEmail)}
              >
                Request Password Reset
              </Button>
            </Row>
          </Container>
        </div>
      </Form>
    </div>
  );
}

export default Forgot;
