import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Modal, Spinner, Container, Alert,
} from 'react-bootstrap';

import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';

import {
  deleteDataCollectionAccount,
  fetchSubscriptionSettings,
  getDataCollectionAccounts,
  updateSubscriptionSettings,
} from '../../controllers/subscription-service';
import { DataCollectionAccount, SubscriptionSettings } from '../../controllers/subscription-service/types';
import { AsyncState } from '../../utils/webRequests.type';

// import './SubscriptionSettingsModal.css';
import { Config } from '../../icons';
import DataCollectionAccountFrame from './DataCollectionAccountFrame';
import { selectOrganizationData, selectOrganizationStatus, selectSettingRequirements } from '../../reducers/orgAdmin/orgAdminSlice';
import NewDataCollectionAccount from './NewDataCollectionAccountCard';

type SubscriptionSettingsModalProps = {
  isConfigurable: boolean,
  isSettingEnabled: boolean,
  subscriptionName: string,
  offeredSubscriptionId: string,
  configurationLocations: string[],
};

export default function SubscriptionSettingsModal({
  isConfigurable, isSettingEnabled, subscriptionName, offeredSubscriptionId, configurationLocations,
}: SubscriptionSettingsModalProps) {
  const [show, setShow] = useState<boolean>(false);

  const settingRequirements = useSelector(selectSettingRequirements)
    .filter(({ offered_subscription_id }) => offered_subscription_id === offeredSubscriptionId);
  const orgId = useSelector(selectOrganizationData).id;
  const orgLoadStatus = useSelector(selectOrganizationStatus);
  const [submitState, setSubmitState] = useState<AsyncState>('uninitialized');
  const [establishedSettings, setEstablishedSettings] = useState<SubscriptionSettings[]>([]);
  const [establishededSettingsState, setEstablishedSettingsStatus] = useState<AsyncState>('uninitialized');
  const [dataCollectionAccounts, setDataCollectionAccounts] = useState<DataCollectionAccount[]>([]);
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const resetModal = () => {
    setSubmitState('uninitialized');
    setEstablishedSettingsStatus('uninitialized');
  };

  const updateRemoteDataCollectionAccounts = async () => {
    setEstablishedSettingsStatus('loading');
    try {
      const { data: dcaData } = await getDataCollectionAccounts(orgId);
      const relevantDCA = dcaData
        .filter(({ offered_subscription_id }) => offeredSubscriptionId === offered_subscription_id)
        .filter(({ deleted_at }) => !deleted_at)
        .sort((a, b) => a.name.localeCompare(b.name));
      const settingsPromises = relevantDCA.map(async ({ id }) => {
        const { data: settingData } = await fetchSubscriptionSettings(orgId, id);
        return settingData;
      });
      const settingsResults = await Promise.all(settingsPromises);
      setEstablishedSettings(settingsResults.flat());
      setDataCollectionAccounts(relevantDCA);
      setEstablishedSettingsStatus('completed');
    } catch (e) {
      datadogRum.addError(e);
      setEstablishedSettingsStatus('failed');
    }
  };

  useEffect(() => {
    if (show) {
      updateRemoteDataCollectionAccounts();
    } else {
      resetModal();
    }
  }, [show]);

  const settingByDataCollectionAccount = useMemo(() => groupBy(
    establishedSettings,
    ({ data_collection_account_id }) => data_collection_account_id,
  ), [establishedSettings]);

  if (!isSettingEnabled && isConfigurable) {
    return <p className="text-coral-red">is configurable once setting is enabled</p>;
  }

  const handleHide = () => {
    setShow(submitState === 'loading');
  };

  const handleShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
  };

  const isLoading = ['loading', 'uninitialized'].includes(establishededSettingsState);

  return (
    <>
      <Button
        hidden={!isConfigurable}
        className="my-1 bg-physics-white"
        onClick={handleShow}
      >
        Configure Settings
        <Config className="ms-2" fill="var(--coral-red)" strokeWidth="4" scale="50%" />
      </Button>
      <Modal
        show={show}
        onHide={handleHide}
        backdrop="static"
        size="lg"
        dialogClassName="SubscriptionSettingsModalContainer"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {subscriptionName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="vh-75 overflow-x-scroll" ref={modalBodyRef}>
          {
            isLoading
              ? (
                <Container className="d-flex justify-content-center h-50" data-testid="loading-spinner-container">
                  <Spinner className="my-auto" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Container>
              )
              : (
                <Container className="overflow-scroll" onClickCapture={() => { }}>
                  <Container>
                    {
                      dataCollectionAccounts.map(({ id }) => (
                        <DataCollectionAccountFrame
                          dcaAccountName="name"
                          key={`setting-modal-DataCollectionAccountFrame-${id}`}
                          settingRequirements={settingRequirements}
                          dataCollectionAccountId={id}
                          settingData={
                            id in settingByDataCollectionAccount
                              ? settingByDataCollectionAccount[id]
                              : []
                            }
                          onDelete={async () => {
                            await deleteDataCollectionAccount(
                              orgId,
                              id,
                            ).finally(updateRemoteDataCollectionAccounts);
                          }}
                          onSave={async (formData) => {
                            await updateSubscriptionSettings(
                              orgId,
                              formData,
                              id,
                            ).then(updateRemoteDataCollectionAccounts);
                          }}
                        />
                      ))
                    }
                    <Alert
                      variant="danger"
                      show={
                        establishededSettingsState === 'failed'
                        || settingRequirements.length === 0
                        || orgLoadStatus === 'failed'
                      }
                    >
                      something went wrong with this subscription setting.
                    </Alert>
                    <NewDataCollectionAccount
                      allowCreateNew={configurationLocations.includes('ManagementApp')}
                      settingRequirements={settingRequirements}
                      onSave={updateRemoteDataCollectionAccounts}
                    />
                  </Container>
                </Container>
              )
          }
        </Modal.Body>
        <Modal.Footer>
          <Container className="d-flex justify-content-end">
            <Button variant="danger" disabled={submitState === 'loading'} onClick={handleHide}>
              Close
            </Button>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
