import { AxiosError } from 'axios';
import { FastApiDetailedError, FastApiError } from '../controllers/types';

export const errorMessageReducer = (
  message_list: string[],
  msg_object: FastApiDetailedError,
): string[] => {
  message_list.push(msg_object.msg);
  return message_list;
};

export const extractErrorMessage = (
  error: AxiosError<FastApiError>,
): string => {
  // set variable with default value
  let { message } = error;
  const responseDetail = error?.response?.data?.detail;

  // handle if a simple string
  if (typeof responseDetail === 'string') {
    message = responseDetail;
  }

  // handle if an array of FastApiDetailedError: reduce across all messages
  if (Array.isArray(responseDetail)) {
    message = responseDetail.reduce(errorMessageReducer, []).join(', ');
  }

  return message;
};
