import React, { useState } from 'react';
import GlobalConfig from '../../app/config';
import SnackBar from '../snackBar/snackBar';

function QuicksightWarningSnackBar() {
  if (GlobalConfig.config.ENV !== 'development') {
    return null;
  }
  const [showQSWarning, setShowQSWarning] = useState<boolean>(true);
  return (
    <SnackBar
      style={{
        position: 'absolute',
        top: '2.8125rem',
        width: '40vw',
        zIndex: 1000,
        left: '30vw',
        textAlign: 'center',
      }}
      header="Warning"
      alertText="Changes here may affect PRODUCTION data and users: Quicksights dashboards are shared with both environments.  Please be careful and mindful of your actions."
      show={showQSWarning}
      data-testid="dev-snackbar-warning"
      onClose={() => setShowQSWarning(false)}
    />
  );
}

export default QuicksightWarningSnackBar;
