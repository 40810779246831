import { SubscriptionType } from '../../controllers/subscription-service/types';
import { LeftNavView } from '../../utils/masterViews';
import { englishToCollectionTypeMap } from './messageTemplates';

// utility for NotificationCreate to reduce repeated code when filtering
// the issues list
//
// the general idea:
// if we are providing filter options for group 'x' (like 'retailerName')
//   then only consider options supplied from the other 2 groups when
//   determining what options to display.

export type FilterSubscriptionsRequestedResultType = (
  'retailerName'
  | 'collectionType'
  | 'featureName'
);

const filterSubscriptions = (
  offeredSubscriptionList: SubscriptionType[],
  masterViewList: LeftNavView[],
  retailerMap: { [key: string]: string },
  retailerFilter: string[],
  collectionTypeFilter: string[],
  featureListFilter: string[],
  requestedResult: FilterSubscriptionsRequestedResultType,
) => {
  let tempSubscriptionList = [...offeredSubscriptionList];

  if (requestedResult !== 'retailerName' && retailerFilter.length > 0) {
    tempSubscriptionList = tempSubscriptionList.filter(
      (s: SubscriptionType) => (
        retailerFilter.map(
          (r: string) => ((r in retailerMap) ? retailerMap[r] : null),
        ).includes(s.retailer_id)
      ),
    );
  }

  if (requestedResult !== 'collectionType' && collectionTypeFilter.length > 0) {
    tempSubscriptionList = tempSubscriptionList.filter(
      (s: SubscriptionType) => (
        collectionTypeFilter
          .map((ct) => englishToCollectionTypeMap(ct))
          .includes(s.collection_type)
      ),
    );
  }

  if (requestedResult !== 'featureName' && retailerFilter.length > 0) {
    tempSubscriptionList = tempSubscriptionList.filter(
      (s: SubscriptionType) => (
        featureListFilter.length === 0
        || masterViewList.filter(
          (view: LeftNavView) => (
            featureListFilter.includes(view.friendly_name)
            && view.required_collection_types.includes(s.collection_type)
          ),
        ).length > 0
      ),
    );
  }

  return tempSubscriptionList;
};

export default filterSubscriptions;
