/* eslint-disable import/no-cycle */

// function reviews the change of possible options for a GROUP
// and the change of selected options for a GROUP.
// if there is a change, then perform the appropriate state change
//   (and trigger the appropriate hooks)
const updateListAndFilter = (
  newDataList: string[],
  oldDataList: string[],
  setDataList: React.Dispatch<React.SetStateAction<string[]>>,
  newDataFilter: string[],
  oldDatafilter: string[],
  setDataFilter: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  if (JSON.stringify(newDataList) !== JSON.stringify(oldDataList)) {
    setDataList(newDataList);
    // if the source list didn't change.. when we don't need to possibly
    // check/update the selected options list.
    if (JSON.stringify(newDataFilter) !== JSON.stringify(oldDatafilter)) {
      setDataFilter(newDataFilter);
    }
  }
};

export default updateListAndFilter;
