import axios, { AxiosInstance } from 'axios';
import { headers } from '..';
import { useAuthenticationInterceptors } from '../auth';

let emailReportService: AxiosInstance;

export const initializeEmailReportServiceController = (serviceUrl: string) => {
  emailReportService = axios.create({
    baseURL: serviceUrl,
  });
  useAuthenticationInterceptors(emailReportService);
};

export const deleteEmailReportServiceOrganizationLegacyReports = async (
  orgId: number,
) => emailReportService.delete(`/organization/${orgId}/legacy_reports/`, { headers: headers(orgId) });
