import React, {
  useRef, useState,
} from 'react';
import {
  Button, FormGroup, InputGroup, Modal, Form, Spinner, NavDropdown,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { postOrganization } from '../../controllers/user-service';
import { AsyncState } from '../../utils/webRequests.type';
import { selectOrganizationsData, selectOrganizationsStatus } from '../../reducers/organizations/organizationsSlice';

export default function CreateOrgModal() {
  const [show, setShow] = useState<boolean>(false);
  const [isOrgNameInValid, setOrgNameInValid] = useState<boolean>(true);
  const [createStatus, setCreateStatus] = useState<AsyncState>('uninitialized');
  const orgNameRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const orgDataStatus = useSelector(selectOrganizationsStatus);
  const organizationsNames = useSelector(selectOrganizationsData).map((org) => org.name);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    if (createStatus === 'loading') {
      return;
    }
    setOrgNameInValid(true);
    setCreateStatus('uninitialized');
    if (orgNameRef.current) {
      orgNameRef.current.value = '';
    }
    setShow(false);
  };

  const handleOrgNameInput = (() => {
    setCreateStatus('uninitialized');
    setOrgNameInValid(
      orgNameRef.current != null
      && organizationsNames.includes(orgNameRef.current.value.toLowerCase()),
    );
  });

  const handleCreateOrg = (() => {
    setCreateStatus('loading');
    if (orgNameRef.current) {
      postOrganization(
        {
          name: orgNameRef.current.value,
          status: 'active',
          modules: {},
        },
      ).then(({ data: newOrgData }) => {
        navigate(`/orgAdmin/${newOrgData.id}`);
        handleHide();
      })
        .catch(() => {
          setCreateStatus('failed');
        });
    }
  });
  return (
    <>
      <NavDropdown.Item
        onClick={handleShow}
        data-testid="linkNotificationAdmin"
      >
        <p>Create New Organization</p>
        <p className="tooltip-color">
          Creates a new organization
        </p>
      </NavDropdown.Item>
      <Modal
        show={show}
        onHide={handleHide}
      >
        <Modal.Body>
          {
            orgDataStatus === 'loading'
              ? <Spinner variant="grow" />
              : (
                <Form validated onSubmit={(e) => e.preventDefault()}>
                  <FormGroup>
                    <InputGroup hasValidation>
                      <InputGroup.Text>Organization Name</InputGroup.Text>
                      <Form.Control
                        disabled={createStatus === 'loading'}
                        ref={orgNameRef}
                        type="text"
                        required
                        isInvalid={isOrgNameInValid || createStatus === 'failed'}
                        onChange={handleOrgNameInput}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose a unique organization name
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Button
                      className="float-right w-50"
                      disabled={isOrgNameInValid || createStatus === 'failed'}
                      onClick={handleCreateOrg}
                    >
                      {createStatus === 'loading' ? <Spinner size="sm" animation="grow" /> : 'Create Organization'}
                    </Button>
                  </FormGroup>
                </Form>
              )
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
