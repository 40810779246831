/* eslint-disable import/no-cycle */

import { DisplayedIssue } from '../../controllers/maintenance-service/types';

// hash that is used to help key notification messages (to group)
// notifications with the same message / tags together
export const dumbHash = (s: string) => {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i += 1) {
    const ch = s.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (((hash << 5) - hash) + ch);
  }
  return hash;
};

export const filterIssues = (
  issues: DisplayedIssue[],
  megaFilter: string[],
  queryString: string,
) => {
  const temp = issues
    .filter(
      (issue) => issue.message.toLowerCase().includes(queryString.toLowerCase()),
    )
    .filter(
      (issue) => megaFilter.length === 0
        || issue.tags?.some(
          (tag) => megaFilter.includes(tag),
        ),
    );
  return temp;
};

// rather than making one long string.. we're making a short string with
// the help of dumbHash
export const generateIssueHash = (
  message: string,
  type: string,
  tags: string[],
) => dumbHash(`${message}.${type}.${tags.sort().join('.')}`).toString(36);

export const sortIssuesByFieldGenerator = (
  sortField: keyof DisplayedIssue,
  sortASC: boolean,
) => (
  (a: DisplayedIssue, b: DisplayedIssue) => {
    if (
      typeof a[sortField] === 'undefined'
      || typeof b[sortField] === 'undefined'
    ) {
      return 0;
    }
    const fieldA = (
      Array.isArray(a[sortField])
    ) ? (a[sortField] as []).length : a[sortField];
    const fieldB = (
      Array.isArray(b[sortField])
    ) ? (b[sortField] as []).length : b[sortField];

    if (fieldA === fieldB) {
      return 0;
    }
    if (sortASC) {
      return (fieldA ?? '') > (fieldB ?? '') ? -1 : 1;
    }
    return (fieldA ?? '') > (fieldB ?? '') ? 1 : -1;
  }
);
