/* eslint-disable max-len */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { datadogRum } from '@datadog/browser-rum';
import {
  isCognitoLoggedin,
} from '../../controllers/auth';
import {
  fetchMasterViews,
  fetchOrganization,
  fetchUsers,
} from '../../controllers/user-service';
import {
  ModuleType,
  OrganizationType,
} from '../../controllers/user-service/types';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import { AsyncState } from '../../utils/webRequests.type';
import { fetchOrganizationSubscriptions, fetchSubscriptionSettingsRequirements, fetchSubscriptions } from '../../controllers/subscription-service';
import { fetchRetailers } from '../../controllers/product-service';
import {
  CollectionType, OrganizationSubscription, SubscriptionSettingRequirements, SubscriptionType,
} from '../../controllers/subscription-service/types';
import { LeftNavView } from '../../utils/masterViews';
import { getTodayAsString } from '../../utils/time';

export type LoadOrganizationDataPayload = {
  organizationData: OrganizationType;
  ownerEmails: string[];
  organizationSubscriptionsData: OrganizationSubscription[];
  subscribedRetailerList: string[];
  subscribedViewList: string[];
  settingsRequirements: SubscriptionSettingRequirements[];
  subscribedCollectionTypeMap: CollectionTypeMap
};

export type LoadOrganizationResourcePayload = {
  offeredSubscriptions: SubscriptionType[],
  retailerMap: { [key: string]: string };
  masterViewList: LeftNavView[];
};

type CollectionTypeMap = {
  [key in CollectionType]?: string;
};

export type OrgAdminState = {
  // pulling state of pulled organization data (view and subscriptions)
  // (covers `organizationData` and `organizationSubscriptionsData`)
  organizationStatus: AsyncState;

  // data from user-service (for the views)
  organizationData: OrganizationType;
  ownerEmails: string[];

  // pulling org-subscription data from subscription-service
  organizationSubscriptionsData: OrganizationSubscription[];

  // organization subscription settings for a given org
  settingsRequirements: SubscriptionSettingRequirements[];

  // manages the combined state of
  // [offeredSubscriptionsData, retailerMapData, masterViewList]
  resourceStatus: AsyncState;

  // pulling offered-subscription data from subscription-service
  offeredSubscriptions: SubscriptionType[],

  // pulling retailer data from product-service
  retailerMap: { [key: string]: string };

  // pulling the app-wide subscription-view mapping
  masterViewList: LeftNavView[];

  // other
  subscribedRetailerList: string[];
  subscribedViewList: string[];

  subscribedCollectionTypeMap: CollectionTypeMap;

  requestedAction: string | null;
};

export const initialState: Readonly<OrgAdminState> = {
  organizationStatus: 'uninitialized',
  organizationData: {
    id: -1,
    name: '',
    status: 'active',
    created_at: null,
    updated_at: null,
    deleted_at: null,
    modules: {},
  },
  ownerEmails: [],
  organizationSubscriptionsData: [],
  settingsRequirements: [],

  resourceStatus: 'uninitialized',
  offeredSubscriptions: [],
  retailerMap: {},
  masterViewList: [],

  // Now calculated as part of the resolve of the loadOrganizationData thunk
  subscribedRetailerList: [],
  subscribedViewList: [],
  subscribedCollectionTypeMap: {},

  requestedAction: null,
};

/* ******************************** */
/* helper functions to digest data */
/* ******************************** */

const today = getTodayAsString();

// list of subscribed retailer names, in alpha order
// and deduping the list
const generateSubscribedRetailerList = (
  subscriptions: OrganizationSubscription[],
): string[] => [
  ...new Set(subscriptions
    .filter(({ deactivated_at }) => deactivated_at > today)
    .map(({ retailer_name }) => retailer_name)),
].sort();

// list of subscribed views
// yes, it makes an array of arrays,
// but flat() just makes it an array of strings.
const generateSubscribedViewList = (
  modules: { [module: string]: ModuleType },
): string[] => Object.values(modules).filter(
  ({ expiration_date }) => expiration_date > today,
).map(
  (module) => module.view_data.map(
    (viewData) => viewData.friendly_name,
  ),
).flat()
  .sort();

// map of collection types:
const generateSubscribedCollectionTypeMap = (
  subscriptions: OrganizationSubscription[],
) => subscriptions.reduce<CollectionTypeMap>(
  (acc, curr: OrganizationSubscription) => {
    if (!curr.collection_type || curr.deactivated_at <= today) {
      return acc;
    }
    return {
      ...acc,
      [curr.collection_type]: curr.deactivated_at,
    };
  },
  {},
);

// thunk to load Subscription and Organization data
export const loadOrganizationData = createAsyncThunk(
  'orgAdmin/fetchOrganizationData',
  async (orgId: number): Promise<LoadOrganizationDataPayload> => {
    if (isCognitoLoggedin()) {
      const loadOrganizationViewsPromise = fetchOrganization(orgId)
        .then(({ data: org }) => {
          if (org === undefined) {
            throw Error('Error fetching org data');
          }
          return org;
        });

      const loadOrganizationOwnersPromise = fetchUsers(orgId, { grants: ['ORG_OWNER'] })
        .then(({ data }) => (
          data.filter(({ grants }) => (
            orgId !== undefined
            && String(orgId) in grants
            && grants[orgId].includes('ORG_OWNER')
          )).map(({ email }) => email)
        ));

      const loadOrganizationSubscriptionsPromise = fetchOrganizationSubscriptions(orgId)
        .then(({ data }) => data);
      const loadsettingsRequirementPromise = fetchSubscriptionSettingsRequirements(orgId)
        .then(({ data }) => data);

      return Promise.all([
        loadOrganizationViewsPromise,
        loadOrganizationOwnersPromise,
        loadOrganizationSubscriptionsPromise,
        loadsettingsRequirementPromise,
      ])
        .then(
          ([
            organizationData,
            ownerEmails,
            organizationSubscriptionsData,
            settingsRequirements,
          ]) => ({
            organizationData,
            ownerEmails,
            organizationSubscriptionsData,
            settingsRequirements,
            subscribedRetailerList: generateSubscribedRetailerList(organizationSubscriptionsData),
            subscribedViewList: generateSubscribedViewList(organizationData.modules),
            subscribedCollectionTypeMap: generateSubscribedCollectionTypeMap(organizationSubscriptionsData),
          }),
        );
    }
    datadogRum.addError('loadOrganizationData thunk called, but user is not logged in.');
    throw Error('User is not logged in');
  },
);

export const loadOrganizationResources = createAsyncThunk(
  'orgAdmin/fetchOrgainizationResources',
  async (): Promise<LoadOrganizationResourcePayload> => {
    if (isCognitoLoggedin()) {
      // load offered subscriptions from subscription-service
      const loadOfferedSubscriptionsPromise = fetchSubscriptions()
        .then(({ data }) => data);

      // load retialers from retailer-service
      const loadRetailerMapPromise = fetchRetailers()
        .then(
          ({ data }) => data.reduce<{ [key: string]: string }>(
            (acc, curr) => ({
              ...acc,
              [curr.id]: curr.name,
            }),
            {},
          ),
        );

      // load master view list from user-service
      const loadMasterViewListPromise = fetchMasterViews()
        .then(({ data }) => data);

      return Promise.all([
        loadOfferedSubscriptionsPromise,
        loadRetailerMapPromise,
        loadMasterViewListPromise,
      ])
        .then(
          ([offeredSubscriptions, retailerMap, masterViewList]) => ({
            offeredSubscriptions,
            retailerMap,
            masterViewList,
          }),
        );
    }
    datadogRum.addError('loadOrganizationResources thunk called, but user is not logged in.');
    throw Error('User is not logged in');
  },
);

export const OrgAdminSlice = createSlice({
  name: 'orgAdmin',
  initialState,
  reducers: {
    resetOrganizationData(state) {
      state.organizationData = initialState.organizationData;
      state.ownerEmails = initialState.ownerEmails;
      state.organizationSubscriptionsData = initialState.organizationSubscriptionsData;
      state.subscribedRetailerList = initialState.subscribedRetailerList;
      state.subscribedViewList = initialState.subscribedViewList;
      state.subscribedCollectionTypeMap = initialState.subscribedCollectionTypeMap;

      state.organizationStatus = initialState.organizationStatus;
    },
    setOrganizationRequestedAction(state, action) {
      state.requestedAction = action.payload;
    },
    setOrganizationStatus(state, action) {
      state.organizationStatus = action.payload;
    },

    setResourceStatus(state, action) {
      state.resourceStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // loadOrganizationData
      .addCase(loadOrganizationData.pending, (state) => {
        state.organizationStatus = 'loading';
      })
      .addCase(loadOrganizationData.fulfilled, (state, action) => {
        // raw data
        state.organizationData = action.payload.organizationData;
        state.ownerEmails = action.payload.ownerEmails;
        state.organizationSubscriptionsData = action.payload.organizationSubscriptionsData;
        // calculated data
        state.subscribedRetailerList = action.payload.subscribedRetailerList;
        state.subscribedViewList = action.payload.subscribedViewList;
        state.subscribedCollectionTypeMap = action.payload.subscribedCollectionTypeMap;
        state.settingsRequirements = action.payload.settingsRequirements;

        state.organizationStatus = 'completed';
      })
      .addCase(loadOrganizationData.rejected, (state) => {
        state.organizationData = initialState.organizationData;
        state.ownerEmails = initialState.ownerEmails;
        state.organizationSubscriptionsData = initialState.organizationSubscriptionsData;
        state.subscribedRetailerList = initialState.subscribedRetailerList;
        state.subscribedViewList = initialState.subscribedViewList;
        state.subscribedCollectionTypeMap = initialState.subscribedCollectionTypeMap;
        state.settingsRequirements = initialState.settingsRequirements;

        state.organizationStatus = 'failed';
      })

      // loadOfferedSubscriptions
      .addCase(loadOrganizationResources.pending, (state) => {
        state.resourceStatus = 'loading';
      })
      .addCase(loadOrganizationResources.fulfilled, (state, action) => {
        state.offeredSubscriptions = action.payload.offeredSubscriptions;
        state.retailerMap = action.payload.retailerMap;
        state.masterViewList = action.payload.masterViewList;
        state.resourceStatus = 'completed';
      })
      .addCase(loadOrganizationResources.rejected, (state) => {
        state.offeredSubscriptions = initialState.offeredSubscriptions;
        state.retailerMap = initialState.retailerMap;
        state.masterViewList = initialState.masterViewList;
        state.resourceStatus = 'failed';
      });
  },
});

export const selectOrganizationStatus = (state: RootState) => state.orgAdmin.organizationStatus;
export const selectOrganizationActive = (state: RootState) => state.orgAdmin.organizationData.status === 'active';
export const selectOrganizationData = (state: RootState) => state.orgAdmin.organizationData;
export const selectSettingRequirements = (state: RootState) => state.orgAdmin.settingsRequirements;
export const selectOwnerEmails = (state: RootState) => state.orgAdmin.ownerEmails;

export const selectSubscriptionsData = (state: RootState) => state.orgAdmin.organizationSubscriptionsData;

export const selectResourceStatus = (state: RootState) => state.orgAdmin.resourceStatus;
export const selectOfferedSubscriptions = (state: RootState) => state.orgAdmin.offeredSubscriptions;
export const selectRetailerMap = (state: RootState) => state.orgAdmin.retailerMap;
export const selectMasterViewList = (state: RootState) => state.orgAdmin.masterViewList;

export const selectSubscribedRetailerList = (state: RootState) => state.orgAdmin.subscribedRetailerList;
export const selectSubscribedViewList = (state: RootState) => state.orgAdmin.subscribedViewList;
export const selectSubscribedCollectionTypeMap = (state: RootState) => state.orgAdmin.subscribedCollectionTypeMap;

export const selectOrganizationRequestedAction = (state: RootState) => state.orgAdmin.requestedAction;

export const {
  resetOrganizationData,
  setOrganizationStatus,
  setOrganizationRequestedAction,
  setResourceStatus,
} = OrgAdminSlice.actions;

export default OrgAdminSlice.reducer;
