import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spinner, Button, Form } from 'react-bootstrap';
import {
  isCognitoLoggedin,
  getTokenValues,
  login,
  logout,
  validateEmail,
} from '../../controllers/auth';
import { actions, AppDispatch } from '../../app/store';
import NewPasswordRequiredForm from './NewPasswordRequiredForm';
import './Login.css';
import { AsyncState, LocationState } from '../../utils/webRequests.type';
import { updateProfile } from '../../reducers/profile/profileSlice';
import { updateOrganizations } from '../../reducers/organizations/organizationsSlice';

function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loginState, setLoginState] = useState<AsyncState>('uninitialized');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [resetPasswordSession, setResetPasswordSession] = useState<any | undefined>(undefined);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { from } = location.state as LocationState || {};
  const redirectPath = from ?? '/';
  useEffect(() => {
    if (loginState === 'completed' && isCognitoLoggedin()) {
      dispatch(updateProfile());
      dispatch(updateOrganizations());
      navigate(redirectPath, { replace: true });
    }
  }, [loginState]);

  const loginFail = (message: string) => {
    setEmail('');
    setPassword('');
    setLoginState('failed');
    setErrorMessage(message ?? 'Failed to login');
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoginState('loading');
    event.persist();
    event.currentTarget.reset();
    event.preventDefault();
    await login(email, password)
      .then((result) => {
        if (result.status === 'NEWPASSWORDREQUIRED') {
          setResetPasswordSession(result.body);
        }
        // test if org 0
        const userTokenValues = getTokenValues();
        if (userTokenValues === null
          || !(Object.keys(userTokenValues.grants).includes('0'))
          || userTokenValues.grants['0'].length === 0
        ) {
          logout()
            .then(() => {
              dispatch(actions.resetState);
            });
          loginFail('Access is for Marshall Insights team members only');
        } else {
          setLoginState('completed');
        }
      })
      .catch((result) => {
        loginFail(result.body?.message);
      });
  };

  if (resetPasswordSession) {
    return (
      <div className="login-page display-grid container justify-content-center">
        <NewPasswordRequiredForm
          session={resetPasswordSession}
          redirectPath={redirectPath}
        />
      </div>
    );
  }
  return (
    <div id="login-page" className="login-page display-grid container justify-content-center ">
      <Form
        onSubmit={handleSubmit}
        id="loginForm"
        className="align-self-center"
      >
        <img className="row" src="/assets/images/Marshall_logo_insights.svg" alt="Marshall Insights" />
        <Form.Group>
          <Form.Control
            id="username-input"
            name="email"
            type="email"
            autoComplete="off"
            className="input-block-level"
            placeholder="Email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            onInput={(e) => setEmail(e.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            id="password-input"
            name="password"
            type="password"
            autoComplete="off"
            className="input-block-level"
            placeholder="Password"
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </Form.Group>
        {
          (errorMessage)
            ? (
              <div role="alert" className="fade alert alert-danger show">
                {errorMessage}
              </div>
            )
            : null
        }
        <div className="login-area">
          <a className="pull-right reset-link text-danger" href="/forgot">Forgot password?</a>
          <br />
          <Button
            variant="primary"
            type="submit"
            disabled={loginState === 'loading' || !validateEmail(email) || (password === '')}
          >
            {loginState === 'loading' ? <Spinner data-testid="login-spinner" size="sm" animation="grow" /> : 'Login' }
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Login;
