import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Search } from '../../icons';
import './SearchInput.css';

const propTypes = {
  placeholder: PropTypes.string,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

type SearchInputProps = PropTypes.InferProps<typeof propTypes>;

function SearchInput(props: SearchInputProps) {
  const {
    placeholder,
    className,
    onChange,
    'data-testid': dataTestID,
    disabled,
  } = props;

  const ref = useRef(null);

  const handleIconClick = () => {
    (ref.current as unknown as HTMLFormElement).focus();
  };

  const iconClass = disabled === true ? 'bg-disabled' : '';

  return (
    <InputGroup className={`${className ?? ''} search-input-group border rounded`}>
      <InputGroup.Text
        className={`search-input-label ${iconClass}`}
        onClick={handleIconClick}
      >
        <Search className="search-input-icon" fill="#CCCCCC" />
      </InputGroup.Text>
      <FormControl
        type="search"
        ref={ref}
        placeholder={placeholder ?? ''}
        data-testid={dataTestID}
        disabled={disabled === true}
        className="search-input-control"
        aria-label="Search"
        onChange={onChange ?? ((e) => e.preventDefault())}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </InputGroup>
  );
}

export default SearchInput;
