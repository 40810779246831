import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import './UnavailablePage.css';

function UnavailablePage() {
  return (
    <div className="page page-not-authorized">
      <PageHeader title="410: Gone" returnText="Back to Home" returnLink="/" />
      <div className="error-message-container">
        <p>
          This page is currently unavailable.
        </p>
        <p>
          Click
          {' '}
          <a className="ma-link" href="/">here</a>
          {' '}
          to go to the homepage.
        </p>
      </div>
    </div>
  );
}

export default UnavailablePage;
