import { NotificationType } from '../../controllers/maintenance-service/types';
import { LeftNavView } from '../../utils/masterViews';

// this function uses the filter to create 1 notification object/preview
// for each unique tag tuple that meets the filter.
// it uses a dummy template what will be replaced with a separate call.
const generateMessages = (
  retailerFilter: string[],
  retailerMap: { [key: string]: string },
  collectionTypeFilter: string[],
  englishToCollectionTypeMap: (k: string) => string,
  featureListFilter: string[],
  masterViewList: LeftNavView[],
) => {
  // need toset up setNewNotifications()
  let tempNotifications: NotificationType[] = [
    {
      key: '',
      message: 'Please select a template',
    },
  ];

  if (retailerFilter.length > 0) {
    tempNotifications = ([] as NotificationType[]).concat(
      ...(retailerFilter.map(
        (retailerName: string) => (
          tempNotifications.map(
            (notification: NotificationType) => ({
              ...notification,
              key: `${notification.key}-${retailerName}`,
              retailerId: retailerMap[retailerName],
              retailerName,
            }),
          )
        ),
      )),
    );
  }
  if (collectionTypeFilter.length > 0) {
    tempNotifications = ([] as NotificationType[]).concat(
      ...(collectionTypeFilter.map(
        (collectionTypeEnglish: string) => (
          tempNotifications.map(
            (notification: NotificationType) => ({
              ...notification,
              key: `${notification.key}-${collectionTypeEnglish}`,
              collectionType: englishToCollectionTypeMap(collectionTypeEnglish),
              collectionTypeEnglish,
            }),
          )
        ),
      )),
    );
  }
  if (featureListFilter.length > 0) {
    tempNotifications = ([] as NotificationType[]).concat(
      ...(featureListFilter.map(
        (featureName: string) => (
          tempNotifications.map(
            (notification: NotificationType) => ({
              ...notification,
              key: `${notification.key}-${featureName}`,
              reportName: masterViewList.filter(
                (view: LeftNavView) => (view.friendly_name === featureName),
              )[0]?.report_name ?? featureName,
              featureName,
            }),
          )
        ),
      )),
    );
  }

  return tempNotifications;
};

export default generateMessages;
