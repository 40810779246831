/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import PasswordValidator from 'password-validator';
import GlobalConfig from '../../app/config';
import jwtDecode from '../../utils/jwtDecode';
import { setAccessToken } from '../user-service'; // eslint-disable-line import/no-cycle
import {
  AlertType,
  AuthResult, DecodedUserServiceToken, SessionPropType, UserGrant,
} from './types';

const schema = new PasswordValidator();
/* eslint-disable newline-per-chained-call */
schema
  .is().min(8, 'Should have a minimum length of 8 characters')
  .is().max(100, 'Should have a maximum length of 100 characters')
  .has().uppercase(1, 'Should have a minimum of 1 uppercase letter')
  .has().lowercase(1, 'Should have a minimum of 1 lowercase letter')
  .has().digits(1, 'Should have a minimum of 1 digit');

const getPool = () => {
  const res = new CognitoUserPool({
    UserPoolId: GlobalConfig.config.COGNITO_POOL_ID,
    ClientId: GlobalConfig.config.COGNITO_CLIENT_ID,
  });
  return res;
};

const getUser = () => {
  const userPool = getPool();
  return userPool.getCurrentUser();
};

const isCognitoLoggedin = () => getUser() != null;

const handleSuccessfulLogin = async (token: string) => {
  localStorage.setItem('cognito_jwt', token);
  await setAccessToken();
};

const login = (email: string, password: string) => new Promise<AuthResult>((resolve, reject) => {
  const userPool = getPool();

  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  let result: AuthResult;
  user.authenticateUser(authDetails, {
    // eslint-disable-next-line
    onSuccess: async (data: any) => {
      await handleSuccessfulLogin(data.getAccessToken().getJwtToken());
      result = {
        status: 'SUCCESS',
        body: undefined,
      };
      resolve(result);
    },
    onFailure: (error: Error) => {
      datadogRum.addError(error);
      result = {
        status: 'FAILURE',
        body: error,
      };
      reject(result);
    },
    // eslint-disable-next-line
    newPasswordRequired: (userAttributes) => {
      // eslint-disable-next-line no-param-reassign
      result = {
        status: 'NEWPASSWORDREQUIRED',
        body: {
          user,
          userAttributes,
        },
      };
      resolve(result);
    },
  });
});

const logout = async () => {
  const user = getUser();
  if (user) {
    await new Promise<void>((resolve) => {
      user.signOut(() => {
        resolve();
      });
    });
  }

  localStorage.removeItem('user_service_token');
  // forces local storage clear in the event that user is invalid
  Object.keys(localStorage).forEach((key) => {
    if (key.toLowerCase().startsWith('cognito')) {
      localStorage.removeItem(key);
    }
  });
  localStorage.removeItem('subscriptionStore');
};

const changePassword = (password: string, newPassword: string) => new Promise((resolve, reject) => {
  const user = getUser();
  if (!user) {
    datadogRum.addError('User could not be retrieved');
    reject(new Error('User could not be retrieved'));
  }
  user?.getSession((error: Error | undefined) => {
    if (error) {
      datadogRum.addError(error);
      reject(error);
    }
    user?.changePassword(password, newPassword, (changeError, status) => {
      if (status === 'SUCCESS') {
        resolve({
          status,
          body: status,
        });
      } else {
        datadogRum.addError(changeError);
        reject(changeError);
      }
    });
  });
});

const changeRequiredPassword = (
  session: SessionPropType,
  password: string,
) => new Promise<AuthResult>((resolve, reject) => {
  const { user, userAttributes } = session;
  let result: AuthResult;
  delete userAttributes.email_verified;
  delete userAttributes.email;
  (user as CognitoUser).completeNewPasswordChallenge(password, userAttributes, {
    onFailure: (error: Error) => {
      datadogRum.addError(error);
      result = {
        status: 'FAILURE',
        body: error,
      };
      reject(result);
    },
    onSuccess: async (data: any) => {
      await handleSuccessfulLogin(data.getAccessToken().getJwtToken());
      result = {
        status: 'SUCCESS',
        body: undefined,
      };
      resolve(result);
    },
  });
});

export const getTokenValues = (): DecodedUserServiceToken | null => {
  try {
    if (localStorage.getItem('user_service_token') == null) {
      datadogRum.addError('no user_service_token set');
      throw Error('no user_service_token set');
    }
    const tokenDump = localStorage.getItem('user_service_token') as string;
    const decodedToken = jwtDecode<DecodedUserServiceToken>(tokenDump);

    return decodedToken;
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    return null;
  }
};

const checkUserServiceToken = () => {
  const tokenValues = getTokenValues();

  // if null: return false
  if (!tokenValues) {
    return false;
  }

  // now check for expiration
  // both values measured in Epoch
  return (tokenValues.exp > (new Date()).getTime() / 1000);
};

const clearToken = () => {
  localStorage.removeItem('user_service_token');
};

export const refreshSession = () => new Promise<AuthResult>((resolve, reject) => {
  const user = getUser();
  try {
    if (user) {
      user?.getSession((err: Error | null, session: CognitoUserSession) => {
        if (!err) {
          const refreshToken = session.getRefreshToken();
          user.refreshSession(
            refreshToken,
            (refreshError: Error, newSession: CognitoUserSession) => {
              if (!refreshError) {
                return handleSuccessfulLogin(
                  newSession.getAccessToken().getJwtToken() as unknown as string,
                ).then(() => resolve({
                  status: 'SUCCESS',
                  body: undefined,
                }));
              }
              throw refreshError;
            },
          );
        } else {
          datadogRum.addError(err);
          throw err;
        }
      });
    } else {
      datadogRum.addError('User is not logged in');
      throw Error('User is not logged in');
    }
  } catch (err) {
    datadogRum.addError(err);
    logout();
    reject(err);
  }
});

const validateEmail = (email: string) => email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const validatePassword = (password: string) => schema.validate(password, { details: true });

export const useAuthenticationInterceptors = (
  service: AxiosInstance,
) => {
  service.interceptors.request.use(async (config) => {
    if (!checkUserServiceToken()) {
      const newConfig = await refreshSession()
        .then(() => ({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('user_service_token')}`,
          } as AxiosRequestHeaders,
        }))
        .catch(Promise.reject.bind(Promise));
      return newConfig;
    }
    return config;
  }, (error) => Promise.reject(error));
};

function hasGrants(currentOrgId: number, requestedGrants: UserGrant[]) {
  const orgString = String(currentOrgId);
  const user = getTokenValues();
  if (user && requestedGrants.length === 0) {
    return true;
  }
  const userOrgs = Object.keys(user?.grants || {});
  const grantArray = requestedGrants.includes('ORG_OWNER')
    ? ['ORG_OWNER']
    : ['ORG_OWNER', 'ORG_ADMIN'].concat(requestedGrants);
  const isAllowed: boolean = user?.grants[orgString]?.some(
    (value) => grantArray.includes(value),
  ) ?? false;
  const isGlobalAdmin: boolean = (userOrgs.includes('0')
    && user?.grants['0'].includes('ADMIN'))
    ?? false;

  return (isAllowed || isGlobalAdmin);
}

const passwordCriteria: string[] = [
  'Should have a minimum length of 8 characters',
  'Should have a maximum length of 100 characters',
  'Should have a minimum of 1 uppercase letter',
  'Should have a minimum of 1 lowercase letter',
  'Should have a minimum of 1 digit',
];

const alerts: { [key: string]: AlertType } = {
  passwordMisMatchAlert: {
    msg: 'Your New Password and Confirm Password do not match.',
    var: 'danger',
  },
  passwordInvalid: {
    msg: 'Your password does not follow the requirements listed above.',
    var: 'danger',
  },
  failure: {
    msg: 'Failed to change your password. Please refresh and try again.',
    var: 'danger',
  },
  passwordSuccess: {
    msg: 'Your password has been successfully updated.',
    var: 'success',
  },
};
export {
  login,
  isCognitoLoggedin,
  logout,
  getPool,
  getUser,
  changePassword,
  changeRequiredPassword,
  checkUserServiceToken,
  clearToken,
  validatePassword,
  validateEmail,
  hasGrants,
  passwordCriteria,
  alerts,
};
