/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
} from 'react-bootstrap';
import './SubscriptionsSwitchBoard.css';
import {
  saveOrganizationSubscriptionsSet,
} from '../../controllers/subscription-service';
import {
  RequestOrganizationSubscriptionSchema,
  OrganizationSubscription,
  OrganizationSubscriptionWithDisplayInformation,
} from '../../controllers/subscription-service/types';
import {
  loadOrganizationData,
  selectOfferedSubscriptions,
  selectRetailerMap,
  selectOrganizationData,
  selectSubscriptionsData,
  selectOrganizationStatus,
  selectResourceStatus,
  selectSettingRequirements,
} from '../../reducers/orgAdmin/orgAdminSlice';
import {
  collectionTypeFlavorTextMap,
  collectionTypeFriendlyMap,
} from '../../utils/subscriptionFlavorText';

import noSpaces from '../../utils/noSpaces';
import { AppDispatch } from '../../app/store';
import SubscriptionSettingsModal from '../SubscriptionSettingsModal/SubscriptionSettingsModal';

function SubscriptionsSwitchBoard() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [subscriptionObject, setSubscriptionObject] = useState<{ [key: string]: { [key: string]: OrganizationSubscriptionWithDisplayInformation } }>({});

  const today = (new Date()).toISOString().substring(0, 10);
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
  const [expirationDate, setExpirationDate] = useState<string>(aYearFromNow.toISOString().substring(0, 10));

  const [retailerFilter, setRetailerFilter] = useState<string>('');

  const organizationData = useSelector(selectOrganizationData);
  const retailerMap = useSelector(selectRetailerMap);
  const offeredSubscriptionList = useSelector(selectOfferedSubscriptions);
  const organizationSubscriptions = useSelector(selectSubscriptionsData);
  const resourceStatus = useSelector(selectResourceStatus);
  const organizationStatus = useSelector(selectOrganizationStatus);

  const settingRequirements = useSelector(selectSettingRequirements);

  const isRetailerActive = (retailerName: string) => {
    let returnValue = false;
    Object.keys(subscriptionObject[retailerName]).forEach((collectionType) => {
      if (subscriptionObject[retailerName][collectionType].deactivated_at > today) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const isRetailerChecked = (retailerName: string) => {
    let returnValue = true;
    Object.keys(subscriptionObject[retailerName]).forEach((collectionType) => {
      if (subscriptionObject[retailerName][collectionType].deactivated_at <= today) {
        returnValue = false;
      }
    });
    return returnValue;
  };

  const changedSubscriptions = () => {
    if (organizationData.id < 1) {
      return [];
    }
    const results: RequestOrganizationSubscriptionSchema[] = [];
    Object.keys(subscriptionObject).forEach(
      (retailerName) => {
        Object.keys(subscriptionObject[retailerName]).forEach(
          (collectionType) => {
            const targetSubscription = subscriptionObject[retailerName][collectionType];
            if (
              // match against existing (a change)
              organizationSubscriptions.some(
                (v) => (
                  v.offered_subscription_id === targetSubscription.offered_subscription_id
                  && v.deactivated_at !== targetSubscription.deactivated_at
                ),
              )
              // or no match (new record)
              || (targetSubscription.deactivated_at > today
                && !(organizationSubscriptions.some(
                  (v) => (
                    v.offered_subscription_id === targetSubscription.offered_subscription_id
                  ),
                ))
              )
            ) {
              const startDate = (targetSubscription.start_date > '') ? targetSubscription.start_date : today;
              const deactivationDate = (targetSubscription.deactivated_at > '') ? targetSubscription.deactivated_at : today;
              results.push({
                organization_id: organizationData.id,
                start_date: startDate,
                deactivated_at: deactivationDate,
                offered_subscription_id: targetSubscription.offered_subscription_id,
              });
            }
          },
        );
      },
    );
    return results;
  };

  // aggregate if I can, else fail.
  useEffect(() => {
    const states = [
      resourceStatus,
      organizationStatus,
    ];
    if (states.includes('failed')) {
      navigate('/error');
    }
    if (states.every((state) => state === 'completed')) {
      const subscriptionTree: { [key: string]: { [key: string]: OrganizationSubscriptionWithDisplayInformation } } = {};
      offeredSubscriptionList.forEach((sub) => {
        const retailerName = retailerMap[sub.retailer_id];
        if (!(retailerName in subscriptionTree)) {
          subscriptionTree[retailerName] = {};
        }
        subscriptionTree[retailerName][sub.collection_type] = {
          id: '',
          organization_id: '',
          offered_subscription_id: sub.id,
          collection_type: sub.collection_type,
          retailer_id: sub.retailer_id,
          retailer_name: retailerName,
          start_date: '',
          deactivated_at: '',
          configuration_location_list: sub.configuration_location_list,
        };
      });

      organizationSubscriptions.forEach((sub) => {
        if (sub.retailer_name in subscriptionTree) {
          subscriptionTree[sub.retailer_name][sub.collection_type] = {
            ...subscriptionTree[sub.retailer_name][sub.collection_type],
            ...sub,
          };
        }
      });

      setSubscriptionObject(subscriptionTree);
    }
  }, [
    resourceStatus,
    organizationStatus,
  ]);

  const toggleSubscription = (
    enabled: boolean,
    subObject: {
      [key: string]: {
        [key: string]: OrganizationSubscription;
      };
    },
    retailerName: string,
    collectionType: string,
  ) => {
    const newSubObject = JSON.parse(JSON.stringify(subObject));
    if (enabled) {
      if (newSubObject[retailerName][collectionType].start_date === '') {
        newSubObject[retailerName][collectionType].start_date = today;
      }
      newSubObject[retailerName][collectionType].deactivated_at = expirationDate;
    } else if (subObject[retailerName][collectionType].start_date < today) {
      newSubObject[retailerName][collectionType].deactivated_at = today;
    } else {
      newSubObject[retailerName][collectionType].start_date = '';
      newSubObject[retailerName][collectionType].deactivated_at = '';
    }
    return newSubObject;
  };

  const handleRetailerChange = (enabled: boolean, retailerName: string) => {
    let newSubscriptionObject = JSON.parse(JSON.stringify(subscriptionObject));
    Object.keys(newSubscriptionObject[retailerName]).forEach((collectionType) => {
      newSubscriptionObject = toggleSubscription(enabled, newSubscriptionObject, retailerName, collectionType);
    });
    setSubscriptionObject(newSubscriptionObject);
  };

  const handleRetailerSubscriptionChange = (
    enabled: boolean,
    retailerName: string,
    collectionType: string,
  ) => {
    setSubscriptionObject(toggleSubscription(enabled, subscriptionObject, retailerName, collectionType));
  };

  const sortByFriendlyName = (a: string, b: string) => {
    if (a === b) {
      return 0;
    }
    const aa = collectionTypeFriendlyMap(a);
    const bb = collectionTypeFriendlyMap(b);
    if (aa > bb) {
      return 1;
    }
    return -1;
  };

  const handleOnChangeExpirationDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = e.currentTarget.value.trim();
    setExpirationDate(dateString);
  };

  const saveSubscriptionsSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();
    if (organizationData.id < 1) {
      return;
    }
    await saveOrganizationSubscriptionsSet(organizationData.id, changedSubscriptions());
    dispatch(loadOrganizationData(organizationData.id));
  };

  return (
    <div
      id="subscription-switchboard"
      className="subscription-switchboard-container"
    >
      <div className="row">
        <div className="col-12">
          <h2>
            Collection Types
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Form.Label htmlFor="retailer-search-input">
            <strong>Retailer Filter:</strong>
          </Form.Label>
          <Form.Control
            id="retailer-search-input"
            data-testid="retailer-search-input"
            type="text"
            value={retailerFilter}
            placeholder="Search Retailers"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRetailerFilter(e.currentTarget.value);
            }}
          />
        </div>
        <div className="col-6">
          <Form.Label htmlFor="subscription-expiration">
            <strong>New Subscriptions Expiration Date:</strong>
          </Form.Label>
          <Form.Control
            id="subscription-expiration"
            data-testid="subscription-expiration"
            type="date"
            value={expirationDate}
            placeholder="New Subscriptions Expiration Date"
            onChange={handleOnChangeExpirationDate}
          />
        </div>
      </div>
      <hr className="mt-3 mb-1" />
      {
        Object.keys(subscriptionObject)
          .sort()
          .filter((retailerName) => retailerName.toLowerCase().includes(retailerFilter.toLowerCase()))
          .map((retailerName, index) => {
            const text = (
              <div>
                <h3>{retailerName}</h3>
                <p className="muted">Toggled on if at least one collection type is gathered for the retailer</p>
              </div>
            );
            const idPrefix = noSpaces(`retailer-${retailerName}`);
            return (
              <div
                id={idPrefix}
                key={`retailer-${retailerName}`}
                className={`row retailerName-row ${(isRetailerActive(retailerName) ? 'highlight' : '')}`}
              >
                <Form.Check
                  type="switch"
                  id={`${idPrefix}-label`}
                  data-testid={`${idPrefix}-label`}
                  label={text}
                  onChange={(event) => handleRetailerChange(event.currentTarget.checked, retailerName)}
                  checked={isRetailerChecked(retailerName)}
                />
                <div key={`collectiontypes-${retailerName}-${index}`}>
                  {
                    Object.keys(subscriptionObject[retailerName])
                      .sort(sortByFriendlyName)
                      .map((collectionType) => {
                        const subModuleText = (
                          <div>
                            <h4>{collectionTypeFriendlyMap(collectionType)}</h4>
                            <p className="muted">
                              {collectionTypeFlavorTextMap(collectionType)}
                            </p>
                          </div>
                        );
                        const elementTag = noSpaces(`${retailerName}-${collectionType}`);
                        const { offered_subscription_id: currentOfferedId } = subscriptionObject[retailerName][collectionType];
                        const isConfigurable = settingRequirements.some(({ offered_subscription_id }) => offered_subscription_id === currentOfferedId);
                        const isEnabled = subscriptionObject[retailerName][collectionType].deactivated_at > today;
                        const configurationLocations = subscriptionObject[retailerName][collectionType].configuration_location_list;
                        return (
                          <div key={`retailerCollection-${elementTag}`} className="submodule-container col-10 offset-2">
                            <Form.Check
                              type="switch"
                              id={`submodule-${elementTag}`}
                              data-testid={`submodule-${elementTag}`}
                              label={(
                                <>
                                  {subModuleText}
                                  <SubscriptionSettingsModal
                                    isConfigurable={isConfigurable}
                                    isSettingEnabled={isEnabled}
                                    configurationLocations={configurationLocations}
                                    subscriptionName={collectionTypeFriendlyMap(collectionType)}
                                    offeredSubscriptionId={currentOfferedId}
                                  />
                                </>
                              )}
                              onChange={(event) => handleRetailerSubscriptionChange(event.currentTarget.checked, retailerName, collectionType)}
                              checked={isEnabled}
                            />
                          </div>
                        );
                      })
                  }
                </div>
              </div>
            );
          })
      }
      <div className="row mt-3 mb-3">
        <div className="col-6">
          &nbsp;
        </div>
        <div className="col-6 text-right">
          <Button
            variant="primary"
            data-testid="save-subscriptions-submit"
            disabled={changedSubscriptions().length === 0}
            onClick={saveSubscriptionsSubmit}
          >
            Update Collection Types
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsSwitchBoard;
