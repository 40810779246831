import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Container,
  ListGroup,
  Form,
  Modal,
  Row,
  NavDropdown,
  Spinner,
  Button,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
  OrganizationType,
} from '../../controllers/user-service/types';
import './SelectOrganizationModal.css';
import SearchInput from '../SearchInput/SearchInput';
import { selectOrganizationsData, selectOrganizationsStatus, updateOrganizations } from '../../reducers/organizations/organizationsSlice';
import { Refresh2 } from '../../icons';
import { AppDispatch } from '../../app/store';

function SelectOrganizationModal() {
  const orgsData = useSelector(selectOrganizationsData);
  const [listFilter, setListFilter] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const orgStatus = useSelector(selectOrganizationsStatus);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const orgModalSortByName = (
    a: OrganizationType,
    b: OrganizationType,
  ) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());

  const handleShow = () => {
    setShowModal(true);
  };

  const handleHide = () => {
    setShowModal(false);
    setListFilter('');
  };

  const handleOrgSelection = (orgId: string) => {
    handleHide();
    navigate(`/orgAdmin/${orgId}`);
  };

  const displayOrgStatus = (status: string | null) => {
    if (status === null || status === 'active') {
      return null;
    }
    return (
      <span className="color-gizmo-grey">
        &nbsp;
        (
        {status}
        )
      </span>
    );
  };

  const displayedOrgs = orgsData
    .filter((org) => (
      (org.name.toLowerCase().includes(listFilter.toLowerCase())
        || org.id.toString() === listFilter)
    ))
    .sort(orgModalSortByName);

  const handleRefreshOrganizations = () => {
    dispatch(updateOrganizations());
  };

  if (orgStatus === 'uninitialized') {
    handleRefreshOrganizations();
  }

  return (
    <>
      <NavDropdown.Item
        onClick={handleShow}
        data-testid="linkSelectOrganizationModal"
      >
        <p>Manage Organization</p>
        <p className="tooltip-color">
          Select an organization to manage
        </p>
      </NavDropdown.Item>
      <Modal
        className="select-organization-modal"
        show={showModal}
        onHide={handleHide}
      >
        <Modal.Body
          data-testid="org-modal"
          className="rounded"
        >
          {orgStatus === 'loading'
            ? <Spinner variant="grow" />
            : (
              <>
                <Row
                  className="mb-2"
                >
                  <Col xs={9}>
                    <h4>
                      Select or add an organization:
                    </h4>
                  </Col>
                  <Col
                    xs={3}
                    className="text-right"
                  >
                    <Button
                      className="ma-btn-link"
                      onClick={handleRefreshOrganizations}
                    >
                      <Refresh2 className="color-gizmo-grey" fill="--gizmo-grey" />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form className="d-flex">
                      <SearchInput
                        className="org-search-input"
                        data-testid="org-search-input"
                        placeholder="Search organization"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setListFilter(e.currentTarget.value);
                        }}
                      />
                    </Form>
                  </Col>
                </Row>
                <Container className="org-option-list">
                  <Row>
                    <ListGroup variant="flush" className="mt-2">
                      {displayedOrgs.map((org) => (
                        <ListGroup.Item
                          onClick={() => handleOrgSelection(String(org.id))}
                          as="a"
                          key={`listorg-${org.id}`}
                        >
                          <Row>
                            <Col
                              xs={12}
                              className="ma-link"
                            >
                              {org.name}
                              {displayOrgStatus(org.status)}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Row>
                </Container>
              </>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SelectOrganizationModal;
