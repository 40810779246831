/* eslint-disable react/jsx-no-useless-fragment */
import React, { } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotAuthorized from '../../pages/NotAuthorized/NotAuthorized';
import {
  hasGrants,
  isCognitoLoggedin,
} from '.';
import {
  UserGrant,
} from './types';

const propTypes = {
  children: PropTypes.node.isRequired,
  requestedGrants: PropTypes.arrayOf(PropTypes.string),
};

type ProtectedRouteType = {
  children: React.ReactNode,
  requestedGrants: UserGrant[],
};

export default function ProtectedRoute(
  {
    children,
    requestedGrants,
  }: ProtectedRouteType,
) {
  const location = useLocation();
  if (!isCognitoLoggedin()) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
        replace
      />
    );
  }
  return hasGrants(0, requestedGrants ?? ['MEMBER'])
    ? <>{children}</>
    : <NotAuthorized />;
}

ProtectedRoute.propTypes = propTypes;

ProtectedRoute.defaultProps = {
  requestedGrants: [],
};
