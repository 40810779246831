import axios, { AxiosInstance } from 'axios';
import { headers } from '..';
import { useAuthenticationInterceptors } from '../auth';

export type RetailerType = {
  id: string,
  name: string,
  homepage: string,
  valid_url_patterns: string | null,
};

export type ProductsGroupByResult = {
  selector: string,
  count: number,
};

export type ProductsGroupBySummaryResult = {
  count: number,
};

let productService: AxiosInstance;

export const initializeProductController = (serviceUrl: string) => {
  productService = axios.create({
    baseURL: serviceUrl,
  });
  useAuthenticationInterceptors(productService);
};

export const fetchRetailers = () => productService.get<RetailerType[]>(
  '/retailers/',
  {
    headers: headers(0),
  },
);
