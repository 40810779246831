/**
 * Retrieves the current date as a string in the format 'YYYY-MM-DD'.
 *
 * @returns A string representing the current date in the format 'YYYY-MM-DD'.
 */
export const getTodayAsString = () => (new Date()).toISOString().substring(0, 10);

/**
 * Checks if a given date string is greater than the current date and time.
 *
 * @param {string | undefined} dateLikeString - The date string to be compared.
 *        If the string is `undefined`, the function returns `false`.
 *
 * @returns {boolean} Returns `true` if the input is greater than the current date.
 */
export const isDateGreaterThanToday = (
  dateLikeString: string | undefined,
) => ((dateLikeString === undefined) ? false : Date.parse(dateLikeString) > Date.now());
