import {
  fetchOrganizationSubscriptions,
} from '../../controllers/subscription-service';

const loadFullOrganizationList = async () => fetchOrganizationSubscriptions(
  0,
  true,
  undefined,
  undefined,
)
  .then(({ data }) => ([...new Set(data.map(
    ({ organization_id }) => Number(organization_id),
  ))]));

export default loadFullOrganizationList;
