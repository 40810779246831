import React from 'react';
import { Tooltip } from 'react-bootstrap';
import './renderOrgListTooltip.css';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const renderOrgListTooltip = (props: any, org_id_list: number[]) => (
  <Tooltip
    className="org-list-tooltip"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    variant="secondary"
  >
    Affected orgs:
    <br />
    {org_id_list.sort((a, b) => (a - b)).map((oid) => String(oid)).join(', ')}
  </Tooltip>
);

export default renderOrgListTooltip;
