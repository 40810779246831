import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationState, MessageMap } from '../../utils/webRequests.type';
import SnackBar from '../snackBar/snackBar';

function NavigationMessage() {
  const location = useLocation();
  const { message } = location.state as LocationState || {};
  const [messageMap, setMessageMap] = useState<MessageMap | undefined>(message);

  useEffect(() => {
    setMessageMap(message);
  }, [message]);

  // clear state in event of reload
  window.history.replaceState({}, document.title);

  if (message !== undefined) {
    return (
      <div className="d-flex justify-content-center">
        <SnackBar
          show={messageMap?.show ?? false}
          alertText={messageMap?.message ?? ''}
          alertType={messageMap?.alertType ?? 'success'}
          header={messageMap?.alertType ?? ''}
          style={{
            zIndex: 1000,
            width: 'auto',
            minWidth: '40rem',
          }}
          onClose={() => setMessageMap({ show: false, message: '', alertType: 'success' })}
        />
      </div>
    );
  }
  return null;
}

export default NavigationMessage;
