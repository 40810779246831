import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import './ErrorPage.css';

type ErrorPageProps = {
  returnLink?: string,
  returnText?: string,

};
function ErrorPage({ returnText, returnLink }: ErrorPageProps) {
  return (
    <div className="page page-not-authorized">
      <PageHeader title="500: Internal Server Error" returnText={returnText} returnLink={returnLink} />
      <div className="error-message-container">
        <p>
          Something went wrong with this page.
        </p>
      </div>
    </div>
  );
}

ErrorPage.defaultProps = {
  returnText: 'Back to Home',
  returnLink: '/',
};

export default ErrorPage;
