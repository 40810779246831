import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { headers } from '..'; // eslint-disable-line import/no-cycle
import partialMatch from '../../utils/partialMatch';
import { // eslint-disable-line import/no-cycle
  checkUserServiceToken,
  hasGrants,
  isCognitoLoggedin,
  refreshSession,
} from '../auth';
import {
  DecodedUserServiceToken,
  UserServiceResponse,
  UserGrant,
} from '../auth/types';
import {
  CreateOrganization,
  EditableUserFields,
  LeftNavItemType,
  OrganizationType,
  SNSTopic,
  UpdateOrganization,
  UpdateUserPayload,
  UserFilterParams,
  UserType,
  ViewDataType,
} from './types';

let userService: AxiosInstance;

// constant to help with user filters
export const UserRoleFilterMap = {
  All: ['Owner', 'Admin', 'Viewer', 'Team Member'],
  Admin: ['Owner', 'Admin'],
  Viewer: ['Viewer'],
  'Team Member': ['Team Member'],
};

let isFetchingToken = false;

export const initializeUserController = (serviceUrl: string) => {
  userService = axios.create({
    baseURL: serviceUrl,
  });

  userService.interceptors.request.use(async (config) => {
    const ignoreCase = [
      {
        method: 'post',
        url: '/user/confirm',
      },
      {
        method: 'post',
        url: '/user/forgot',
      },
      {
        method: 'options',
      },
    ];

    for (let i = 0; i < ignoreCase.length; i += 1) {
      // ignore if matching
      if (partialMatch(ignoreCase[i], config)) {
        return config;
      }
    }

    if (partialMatch({ method: 'get', url: '/token' }, config)) {
      if (isFetchingToken) {
        /*
        eslint-disable-next-line
        no-param-reassign,
        @typescript-eslint/no-explicit-any,
        @typescript-eslint/no-unused-vars,
        no-async-promise-executor
        */
        config.adapter = (_config) => new Promise<any>(async (resolve) => {
          let retry = 0;

          // sleep until token is loaded or retry count is exceeded
          while (isFetchingToken) {
            if (retry < 12) {
              // eslint-disable-next-line no-await-in-loop
              await new Promise((resolveSleep) => { setTimeout(resolveSleep, 200); });
              retry += 1;
            } else {
              throw Error(`failed to resolve token. Retried ${retry} times`);
            }
          }
          const res = {
            data: {
              token: localStorage.getItem('user_service_token'),
            },
            status: 200,
            statusText: 'OK',
            headers: { 'content-type': 'text/plain; charset=utf-8' },
            config,
            request: {},
          };
          // eslint-disable-next-line no-promise-executor-return
          return resolve(res);
        }).finally(() => {
          // set isFetchingToken to false so other waiting calls run;
          isFetchingToken = false;
        });
      } else {
        isFetchingToken = true;
      }
    } else if (!checkUserServiceToken()) {
      const newConfig = await refreshSession()
        .then(() => ({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('user_service_token')}`,
          } as AxiosRequestHeaders,
        }))
        .catch(Promise.reject.bind(Promise));
      return newConfig;
    }
    return config;
  }, (error) => Promise.reject(error));

  userService.interceptors.response.use(
    async (response) => {
      // when first token is fetched flip fetching flag
      if (partialMatch({ method: 'get', url: '/token' }, response.config)) {
        isFetchingToken = false;
      }
      return Promise.resolve(response);
    },
    (error) => Promise.reject(error),
  );
};

const getBearerToken = () => `Bearer ${localStorage.getItem('cognito_jwt')}`;

export const setAccessToken = async () => {
  if (isCognitoLoggedin()) {
    try {
      const response = await userService.get<UserServiceResponse>('/token', {
        headers: {
          Authorization: getBearerToken(),
        },
      });
      localStorage.setItem('user_service_token', response.data.token);
    } catch (e) {
      datadogRum.addError(e);
      console.error(e); // eslint-disable-line no-console
    }
  } else {
    throw Error('user is not logged in');
  }
};

export const fetchOrganizations = async (orgId: number | null = null) => {
  const authHeader = !orgId ? headers() : headers(orgId);
  const result = await userService.get<OrganizationType[]>('/organizations/', {
    headers: authHeader,
  });
  return result;
};

export const fetchOrganization = async (orgId: number) => userService.get<OrganizationType>(`/organization/${orgId}`, {
  headers: headers(orgId),
});

export const hasViewPermission = (
  orgId: string,
  viewPermissions: UserGrant[],
) => hasGrants(Number(orgId), viewPermissions.concat(['VIEWER']));

export const getModuleFromOrgData = (org: OrganizationType): LeftNavItemType[] => {
  const result: LeftNavItemType[] = [];

  if (org.modules) {
    Object.keys(org.modules).forEach((moduleKey: string) => {
      (org.modules[moduleKey].view_data ?? []).forEach((viewData: ViewDataType) => {
        if (hasViewPermission(String(org.id), viewData.valid_permissions)) {
          result.push({
            module: org.name,
            breadcrumb: viewData.breadcrumb,
            friendlyName: viewData.friendly_name,
            path: viewData.path,
            isVisible: viewData.is_visible,
            displayOrder: viewData.display_order ?? 0,
          });
        }
      });
    });
  }
  return result;
};

export const fetchUsers = async (orgId: number, filter_params: UserFilterParams | undefined = undefined) => userService.get<UserType[]>('/users', {
  headers: headers(orgId),
  params: filter_params,
});

export const fetchUser = async (orgId: number, userId: string) => userService.get<UserType>(`/user/${userId}`, {
  headers: headers(orgId),
});

export const fetchViews = async (orgId: number, path: string) => userService.get(`/organization/${orgId}/view/?path=${encodeURIComponent(path)}`, {
  headers: headers(orgId),
});

export const createUser = async (
  orgId: number,
  payload: EditableUserFields,
) => userService.post(
  '/user/',
  // consolidateUserGrants(payload, orgId.toString()),
  payload,
  { headers: headers(orgId) },
);

export const patchUser = async (
  userId: string,
  orgId: number,
  payload: UpdateUserPayload,
) => userService.patch<DecodedUserServiceToken>(
  `/user/${userId}`,
  payload,
  { headers: headers(orgId) },
);

export const updateUser = async (
  userId: string,
  orgId: number,
  payload: UpdateUserPayload,
) => patchUser(
  userId,
  orgId,
  // consolidateUserGrants(payload, orgId.toString()),
  payload,
);

export const forgotPassword = async (email: string) => userService.post('/user/forgot', { email });

export const confirmForgotPassword = async (email: string, confirmationCode: string, password: string) => userService.post('/user/confirm', { email, password, confirmationCode });

export const fetchOrgOwners = async (orgId: number) => {
  const uri = `/user/?organization_id=${orgId}&grants=ORG_OWNER`;
  return userService.get<UserType[]>(uri, {
    headers: headers(orgId),
  });
};

export const fetchUserByEmail = async (email: string) => {
  const uri = `/user/?email=${email}`;
  return userService.get<UserType[]>(uri, {
    headers: headers(0),
  });
};

export const postOrganization = async (
  payload: CreateOrganization,
) => userService.post(
  '/organization/',
  payload,
  { headers: headers(0) },
);

export const patchOrganization = async (
  organizationId: number,
  payload: Partial<UpdateOrganization>,
) => userService.patch(
  `/organization/${String(organizationId)}`,
  payload,
  { headers: headers(organizationId) },
);

export const fetchMasterViews = async () => userService.get(
  '/left-nav-map/',
  {
    headers: headers(0),
  },
);

export const postAnnouncement = async (
  list: SNSTopic,
  subject: string,
  message: string,
) => userService.post(
  '/announcements/',
  {
    list,
    subject,
    message,
  },
  {
    headers: headers(0),
  },
);

export const fetchOrganizationsByView = async (
  report_name: string,
) => userService.get(
  `/organizations-by-view/${report_name}`,
  {
    headers: headers(0),
  },
);

export const postQuickSightDashboardResource = async (
  organizationId: number,
  reportName: string,
) => userService.post(
  '/qs-dashboard-resource/',
  {
    report_name: reportName,
  },
  {
    headers: headers(organizationId),
  },
);

export const deleteQuickSightDashboardResource = async (
  organizationId: number,
  reportName: string,
) => userService.delete(
  `/qs-dashboard-resource/${reportName}`,
  {
    headers: headers(organizationId),
  },
);
