/* istanbul ignore */
import { useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isCognitoLoggedin } from '../controllers/auth';
import { selectProfileData, selectProfileStatus } from '../reducers/profile/profileSlice';

const onRumLogout = () => {
  datadogRum.clearGlobalContext();
  datadogRum.clearUser();
};

export default function useDataDog() {
  const location = useLocation();
  const profileStatus = useSelector(selectProfileStatus);
  const profileData = useSelector(selectProfileData);

  useEffect(() => {
    datadogRum.startView({ name: document.title });
  }, [location.pathname]);

  useEffect(() => {
    if (
      isCognitoLoggedin()
      && profileStatus === 'completed'
    ) {
      // identify user session
      // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm
      datadogRum.setUser({
        id: profileData.user_id,
        name: `${profileData.first_name} ${profileData.last_name}`,
        email: profileData.email,
      });
    }
  }, [profileStatus]);

  useEffect(() => {
    window.addEventListener('user_logout', onRumLogout);
    return () => {
      window.removeEventListener('user_logout', onRumLogout);
    };
  }, []);
}
