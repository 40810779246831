import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions, AppDispatch } from '../../app/store';
import * as auth from '../../controllers/auth';

function Logout() {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const nav = useNavigate();

  useEffect(() => {
    if (!loaded) {
      auth.logout()
        .then(() => {
          setLoaded(true);
          dispatch(actions.resetState);
        }).finally(() => {
          nav('/login');
        });
    }
  }, []);

  return loaded ? (
    <div className="h-100 page container justify-content-center ">
      You can close this tab or login
      {' '}
      <a href="/login">here</a>
      .
    </div>
  ) : (
    <div className="h-100 page container justify-content-center ">
      Logging you out...
    </div>
  );
}

export default Logout;
