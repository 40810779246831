import React, { CSSProperties } from 'react';
import Alert from 'react-bootstrap/Alert';
import { AsyncState } from '../../utils/webRequests.type';
import { ViewsSaveDataListType } from './types'; // eslint-disable-line import/no-cycle

export type ViewsSaveFeedbackProps = {
  dataRef: React.MutableRefObject<ViewsSaveDataListType>
  show: boolean,
  onClose: () => void,
  header: string,
  style: CSSProperties,
};

function ViewsSaveFeedback({
  dataRef,
  show,
  onClose,
  header,
  style,
}: ViewsSaveFeedbackProps) {
  const { data } = dataRef.current;

  const viewStatusRenderText: Record<AsyncState, string> = {
    uninitialized: 'PENDING',
    loading: 'IN PROGRESS',
    completed: 'COMPLETED',
    failed: 'FAILED',
  };

  // determines alertType (border theme) based on data state
  const alertType = () => {
    let failureCount = 0;
    let incompleteCount = 0;
    data.forEach(
      (d) => {
        if (d.status === 'failed') {
          failureCount += 1;
          return;
        }
        if (
          ['create', 'destroy'].includes(d.operation)
          && d.status !== 'completed'
        ) {
          incompleteCount += 1;
        }
      },
    );
    if (failureCount > 0) {
      return 'danger';
    }
    return (incompleteCount > 0) ? 'secondary' : 'success';
  };

  const statusClass = (status: AsyncState) => {
    switch (status) {
      case 'completed':
        return 'text-success';
      case 'failed':
        return 'text-danger';
      default:
        return 'text-secondary';
    }
  };

  const isRunning = data
    .filter((process) => process.operation !== 'ignore')
    .some((process) => ['uninitialized', 'loading'].includes(process.status));

  return (
    <Alert
      show={show && data.length > 0}
      dismissible={!isRunning}
      data-testid="ViewSaveFeedback"
      className="ViewSaveFeedback"
      style={style}
      variant={alertType()}
      onClose={onClose}
    >
      <Alert.Heading>{header}</Alert.Heading>
      <table>
        <thead>
          <tr>
            <th>View/Dashboard</th>
            <th>Action</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map(
              (view) => (
                <tr className="align-top border-top border-white border-5" key={view.reportName}>
                  <td>{view.reportName}</td>
                  <td className="text-capitalize">{view.operation}</td>
                  <td className={statusClass(view.status)}>
                    <span className="text-uppercase font-weight-bold">
                      {(view.operation === 'ignore') ? 'skipped' : viewStatusRenderText[view.status]}
                    </span>
                    {(view.status === 'failed') ? `: ${view.detail}` : null}
                  </td>
                </tr>
              ),
            )
          }
        </tbody>
      </table>

    </Alert>
  );
}

export default ViewsSaveFeedback;
