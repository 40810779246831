import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { datadogRum } from '@datadog/browser-rum';
import { RootState } from '../../app/store'; // eslint-disable-line import/no-cycle
import {
  isCognitoLoggedin,
  getTokenValues,
} from '../../controllers/auth';
import {
  fetchOrganizations,
} from '../../controllers/user-service';
import {
  OrganizationType,
} from '../../controllers/user-service/types';
import { AsyncState } from '../../utils/webRequests.type';

export type OrganizationsState = {
  status: AsyncState;
  data: OrganizationType[];
};

export type ApplicationState = {
  organizations: OrganizationsState,
};

const initialState: OrganizationsState = {
  status: 'uninitialized',
  data: [],
};

export const updateOrganizations = createAsyncThunk(
  'organizations/fetchOrganizations',
  async () => {
    if (isCognitoLoggedin()) {
      const tokenValues = getTokenValues();
      const response = await fetchOrganizations(tokenValues?.default_organization_id);
      return response.data;
    }
    datadogRum.addError('updateOrganizations thunk called, but user is not logged in.');
    throw Error('User is not logged in');
  },
);

export const OrganizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateOrganizations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrganizations.fulfilled, (state, action) => {
        state.status = 'completed';
        state.data = action.payload ?? [];
      })
      .addCase(updateOrganizations.rejected, (state) => {
        state.status = 'failed';
        state.data = initialState.data;
      });
  },
});

export const selectOrganizationsData = (state: RootState) => state.organizations.data;
export const selectOrganizationsStatus = (state: RootState) => state.organizations.status;

export default OrganizationsSlice.reducer;
