import { datadogRum } from '@datadog/browser-rum';
import {
  fetchSubscriptions,
} from '../../controllers/subscription-service';
import {
  SubscriptionType,
} from '../../controllers/subscription-service/types';
import { AsyncState } from '../../utils/webRequests.type';

const loadOfferedSubscriptionList = async (
  offeredSubscriptionLoadStatus: AsyncState,
  setOfferedSubscriptionList: (data: SubscriptionType[]) => void,
  setOfferedSubscriptionLoadStatus: (data: (AsyncState)) => void,
) => {
  if (offeredSubscriptionLoadStatus !== 'uninitialized') {
    return;
  }
  setOfferedSubscriptionLoadStatus('loading');
  try {
    const response = await fetchSubscriptions();
    if ((response.data ?? []).length === 0) {
      datadogRum.addError('invalid subscription data received from server');
      throw new Error('invalid subscription data received from server');
    }
    setOfferedSubscriptionList(response.data);
    setOfferedSubscriptionLoadStatus('completed');
  } catch {
    setOfferedSubscriptionList([]);
    setOfferedSubscriptionLoadStatus('failed');
  }
};

export default loadOfferedSubscriptionList;
