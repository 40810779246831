import { datadogRum } from '@datadog/browser-rum';
import {
  fetchMasterViews,
} from '../../controllers/user-service';
import { LeftNavView } from '../../utils/masterViews';
import { AsyncState } from '../../utils/webRequests.type';

const loadMasterViewList = async (
  masterViewLoadStatus: AsyncState,
  setMasterViewList: (data: LeftNavView[]) => void,
  setMasterViewLoadStatus: (data: (AsyncState)) => void,
) => {
  if (masterViewLoadStatus !== 'uninitialized') {
    return;
  }
  setMasterViewLoadStatus('loading');
  try {
    const response = await fetchMasterViews();
    if ((response.data ?? []).length === 0) {
      datadogRum.addError('invalid view data received from server');
      throw new Error('invalid view data received from server');
    }
    setMasterViewList(response.data);
    setMasterViewLoadStatus('completed');
  } catch {
    setMasterViewList([]);
    setMasterViewLoadStatus('failed');
  }
};

export default loadMasterViewList;
