import React from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AsyncState } from '../../utils/webRequests.type';
import './LeftFilterSelect.css';
import noSpaces from '../../utils/noSpaces';

/*
  This component's purpose is to receive a set of strings,
  and allow the user to select 0-N of them, and submit these
  changes back to state with the supplied hook.

  Props:
  label: Label on top of this component (should be category of
    strings that are being chosen)
  listLoadStatus: whether or not the base list should be available
    for viewing
  listData: the base list of options (strings) that may be selected
    from
  selectedData: subset of listData ... the list of user-selected
    options, as known by state
  setSelectedData: hook to save the new set of selected data back
    to state.
  maxOneSelection: flag to set this component to:
      allow 0-1 options to be selected if TRUE
      use radio buttons
    else:
      allow 0-N options to be selected if FALSE
      use checkboxes
*/

const propTypes = {
  label: PropTypes.string.isRequired,
  listLoadStatus: PropTypes.string.isRequired,
  listData: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedData: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedData: PropTypes.func.isRequired,
  maxOneSelection: PropTypes.bool,
};

type LeftFilterSelectProps = {
  label: string,
  listLoadStatus: AsyncState,
  listData: string[],
  selectedData: string[],
  setSelectedData: (elementList: string[]) => void,
  maxOneSelection?: boolean,
};

function LeftFilterSelect(props: LeftFilterSelectProps) {
  const {
    label,
    listLoadStatus,
    listData,
    selectedData,
    setSelectedData,
    maxOneSelection,
  } = props;

  // passes changes of option selection from this component back
  // to the caller via a state hook.
  const selectChangeHandler = (selectedValue: string) => {
    if (selectedData.includes(selectedValue)) {
      setSelectedData(selectedData.filter(
        (v) => v !== selectedValue,
      ));
    } else {
      setSelectedData(
        maxOneSelection
          ? [selectedValue]
          : [
            ...selectedData,
            selectedValue,
          ],
      );
    }
  };

  const toggleSelectionHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelectedData(
      selectedData.length === 0
        ? listData
        : [],
    );
  };

  return (
    <Accordion
      flush
    >
      {
        listLoadStatus !== 'completed'
          ? (
            <div
              className="mock-Accordion"
            >
              {`${label}: Loading...`}
            </div>
          )
          : (
            <Accordion.Item
              eventKey={`${noSpaces(label)}-filter-select-dropdown`}
              key={`${noSpaces(label)}-filter-select-dropdown`}
              data-testid={`${noSpaces(label)}-filter-select-dropdown`}
              className="left-filter-select"
            >
              <Accordion.Header
                className="left-filter-select-header"
              >
                <h5>
                  {label}
                </h5>
                <div
                  className="accordion-header-filter-text"
                >
                  :
                  &nbsp;
                  {
                    selectedData.length > 1
                      ? (
                        `(${selectedData.length} selections)`
                      )
                      : null
                  }
                  {
                    selectedData.length === 1
                      ? (
                        selectedData[0]
                      )
                      : null
                  }
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {
                  !maxOneSelection
                    ? (
                      <Button
                        size="sm"
                        variant="outline-primary"
                        key={`${noSpaces(label)}-clear`}
                        id={`${noSpaces(label)}-clear`}
                        data-testid={`${noSpaces(label)}-clear`}
                        onClick={toggleSelectionHandler}
                      >
                        {selectedData.length === 0 ? '(Select All)' : '(Select None)'}
                      </Button>
                    )
                    : null
                }
                {
                  listData.map(
                    (s: string) => (
                      <Form.Check
                        type={maxOneSelection ? 'radio' : 'checkbox'}
                        key={`${noSpaces(label)}-${noSpaces(s)}`}
                        id={`${noSpaces(label)}-${noSpaces(s)}`}
                        data-testid={`${noSpaces(label)}-${noSpaces(s)}`}
                        label={s === '' ? '(blank)' : s}
                        checked={selectedData.includes(s)}
                        onChange={() => selectChangeHandler(s)}
                      />
                    ),
                  )
                }
              </Accordion.Body>
            </Accordion.Item>
          )
      }
    </Accordion>
  );
}

LeftFilterSelect.propTypes = propTypes;

LeftFilterSelect.defaultProps = {
  maxOneSelection: false,
};

export default LeftFilterSelect;
