import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { UpArrow } from '../../icons';
import './renderTableHeaderWithArrow.css';

const propTypes = {
  field: PropTypes.string.isRequired,
  sortField: PropTypes.string.isRequired,
  sortASC: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

type RenderTableHeaderWithArrowProps = PropTypes.InferProps<typeof propTypes>;

function RenderTableHeaderWithArrow(props: RenderTableHeaderWithArrowProps) {
  const {
    field,
    sortField,
    sortASC,
    onClickHandler,
    children,
  } = props;
  return (
    <th
      className="minWidth-10-rem table-header-with-arrow"
    >
      <Button
        id={`table-header-w-arrow-${field}`}
        data-testid={`table-header-w-arrow-${field}`}
        onClick={onClickHandler}
      >
        {children}
        {
          sortField === field
            ? <UpArrow className={sortASC ? 'up-arrow' : 'up-arrow flipped'} />
            : null
        }
      </Button>
    </th>
  );
}

RenderTableHeaderWithArrow.propTypes = propTypes;

export default RenderTableHeaderWithArrow;
