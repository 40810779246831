import React, { useRef, useState } from 'react';
import {
  Container,
  Button,
} from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';

import { SubscriptionSettingRequirements } from '../../controllers/subscription-service/types';
import { Plus } from '../../icons';
import DataCollectionAccountFrame from './DataCollectionAccountFrame';
import './newDataCollectionAccountCard.css';
import { selectOrganizationData } from '../../reducers/orgAdmin/orgAdminSlice';
import { createDataCollectionAccount } from '../../controllers/subscription-service';

type NewDataCollectionAccountProp = {
  // passed in value derived from the configuration_location for this subscription.
  allowCreateNew: boolean,
  settingRequirements: SubscriptionSettingRequirements[],
  onSave: () => Promise<void>,
};
export default function NewDataCollectionAccount(
  { allowCreateNew, settingRequirements, onSave }: NewDataCollectionAccountProp,
) {
  const orgId = useSelector(selectOrganizationData).id;
  const [isDisplayed, setDisplayed] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const hasAccountNameKey = settingRequirements.some(({ setting_key }) => setting_key === 'account_name');
  return (
    <Container
      className="col-12 p-0"
    >
      <Button
        className={`ma-btn align-self-center mx-auto col-12 my-auto new-account-card-hide-show-animation ${isDisplayed ? 'hide' : 'show'}`}
        disabled={!allowCreateNew || isDisplayed}
        onClick={() => setDisplayed(true)}
      >
        <Plus className="mx-2" />
        Add New Data Collection Account
      </Button>

      <CSSTransition
        in={isDisplayed}
        nodeRef={containerRef}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div className={`new-account-card-hide-show-animation ${isDisplayed ? 'show' : 'hide'}`}>
          <DataCollectionAccountFrame
            dcaAccountName=""
            settingRequirements={settingRequirements}
            dataCollectionAccountId="(id will be defined on creation)"
            onSave={async (formData) => {
              const accountName = hasAccountNameKey
                ? formData.account_name
                : formData.dca_account_name;
              createDataCollectionAccount(
                orgId,
                {
                  organization_id: orgId,
                  name: accountName as string,
                  offered_subscription_id: settingRequirements[0].offered_subscription_id,
                  population_status: 'filled',
                  settings: formData,
                },
              ).then(onSave)
                .then(() => setDisplayed(false));
            }}
            onDelete={() => { setDisplayed(false); }}
            settingData={[]}
          />
        </div>
      </CSSTransition>
    </Container>
  );
}
