import React, { CSSProperties, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useLocation } from 'react-router-dom';

export type AlertTypes = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';

export type SnackBarProps = {
  testid?: string,
  show: boolean,
  alertType?: AlertTypes,
  onClose: () => void,
  header: string,
  alertText: string,
  style: CSSProperties,
};

function snackBar({
  testid,
  show,
  alertType = 'danger',
  onClose,
  header,
  alertText,
  style,
}: SnackBarProps) {
  const location = useLocation();

  // if the location changes, reset the snackbar
  useEffect(onClose, [location.state]);

  return (
    <Alert
      data-testid={testid}
      style={style}
      variant={alertType}
      onClose={onClose}
      dismissible
      show={show}
    >
      <Alert.Heading>{header}</Alert.Heading>
      <p>
        {alertText}
      </p>
    </Alert>
  );
}

snackBar.defaultProps = {
  testid: 'generic-snackbar',
  onClose: () => { },
};

export default snackBar;
