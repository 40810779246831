import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { headers } from '..';
import { useAuthenticationInterceptors } from '../auth';
import {
  SubscriptionType,
  OrganizationSubscription,
  RequestOrganizationSubscriptionSchema,
  SubscriptionSettings,
  SubscriptionSettingRequirements,
  OrganizationStatusResponse,
  DataCollectionAccount,
  DataCollectionAccountCreatePayload,
} from './types';

let subscriptionService: AxiosInstance;

export const initializeSubscriptionController = (serviceUrl: string) => {
  subscriptionService = axios.create({
    baseURL: serviceUrl,
    // timeout: 2,
  });
  useAuthenticationInterceptors(subscriptionService);
};

export const fetchOrganizationSubscriptions = async (
  orgId: number,
  active = true,
  collection_type_list: string[] = [],
  retailer_list: string[] = [], // expect list of uuids
) => {
  const searchParams = new URLSearchParams();

  searchParams.append('active', active.toString());

  collection_type_list.forEach(
    (collection_type) => {
      searchParams.append('collection_type_list', collection_type);
    },
  );

  retailer_list.forEach(
    (retailer) => {
      searchParams.append('retailers', retailer);
    },
  );

  return subscriptionService.get<OrganizationSubscription[]>(
    '/organization_subscriptions/',
    { headers: headers(orgId), params: searchParams },
  );
};

export const fetchSubscriptions = async () => subscriptionService.get<SubscriptionType[]>('/subscriptions/', { headers: headers(0) });

// extra code is here because this data may be cached.
export const fetchOrganizationSubscribedCollectionTypes = async (
  orgId: number,
) => subscriptionService.get<OrganizationSubscription[]>(
  '/organization_subscriptions/',
  {
    headers: headers(orgId),
  },
);

export const getDataCollectionAccounts = (
  orgId: number,
) => subscriptionService.get<DataCollectionAccount[]>(
  '/data_collection_accounts/',
  {
    headers: headers(orgId),
  },
);

export const createDataCollectionAccount = (
  orgId: number,
  payload: DataCollectionAccountCreatePayload,
) => subscriptionService.post<DataCollectionAccountCreatePayload, DataCollectionAccount>(
  '/data_collection_accounts/',
  payload,
  {
    headers: headers(orgId),
  },
);

export const updateSubscriptionSettings = (
  orgId: number,
  payload: Record<string, string | string[] | number>,
  data_collection_account_id: string,
) => subscriptionService.patch<DataCollectionAccountCreatePayload, DataCollectionAccount>(
  `/data_collection_accounts/${data_collection_account_id}/organization_subscription_settings/`,
  {
    settings: payload,
  },
  {
    headers: headers(orgId),
  },
);

export const deleteDataCollectionAccount = (
  orgId: number,
  data_collection_account_id: string,
) => subscriptionService.delete(
  `/data_collection_accounts/${data_collection_account_id}`,
  {
    headers: headers(orgId),
  },
);

export const fetchSubscriptionSettings = async (
  orgId: number,
  data_collection_account_id: string,
) => subscriptionService.get<SubscriptionSettings[]>(
  '/organization_subscription_settings/',
  {
    headers: headers(orgId),
    params: {
      data_collection_account_id,
    },
  },
);

export const fetchSubscriptionSettingsRequirements = async (
  orgId: number,
  subscriptionId: string | undefined = undefined,
) => subscriptionService.get<SubscriptionSettingRequirements[]>(
  '/subscription_settings_requirements/',
  {
    headers: headers(orgId),
    params: {
      offered_subscription_id: subscriptionId,
    },
  },
);

export const saveOrganizationSubscriptions = async (
  orgId: number,
  payload: RequestOrganizationSubscriptionSchema,
) => subscriptionService
  .post<RequestOrganizationSubscriptionSchema, AxiosResponse<OrganizationStatusResponse>>(
  '/organization_subscriptions/',
  payload,
  {
    headers: headers(orgId),
  },
);

export const saveOrganizationSubscriptionsSet = async (
  orgId: number,
  payload: RequestOrganizationSubscriptionSchema[],
) => subscriptionService
  .put<OrganizationSubscription[], AxiosResponse<OrganizationStatusResponse>>(
  '/organization_subscriptions_set/',
  payload,
  {
    headers: headers(orgId),
  },
);

export const disableAllOrganizationSubscriptions = async (
  orgId: number,
) => {
  const { data } = await fetchOrganizationSubscriptions(orgId);
  const disabledOrganizationSubscriptions: RequestOrganizationSubscriptionSchema[] = data.map(
    ({ offered_subscription_id }) => ({
      offered_subscription_id,
      organization_id: orgId,
      deactivated_at: (new Date()).toISOString().substring(0, 10),
    }),
  );
  return saveOrganizationSubscriptionsSet(
    orgId,
    disabledOrganizationSubscriptions,
  );
};
