import React, { ReactNode } from 'react';

import './PageHeader.css';

type PageHeaderProps = {
  title: string | React.ReactNode,
  returnText?: string,
  returnLink?: string,
  children?: ReactNode, // can't be "React.Component"
  titleWidth?: string,
  className?: string,
};

function PageHeader(props: PageHeaderProps) {
  const {
    title,
    returnText,
    returnLink,
    children,
    titleWidth,
    className,
  } = props;
  return (
    <div className={`row w-100 ${className} page-header-container`}>
      <div className={`page-title-header ${titleWidth}`}>
        <h3>{title}</h3>
        {
          (returnText !== '' && returnLink !== '')
          && <a className="ma-link" href={returnLink as string}>{`< ${returnText}`}</a>
        }
      </div>
      <div className="title-button-container pull-right col-6">
        {children}
      </div>
    </div>
  );
}

PageHeader.defaultProps = {
  returnText: '',
  returnLink: '',
  children: null,
  titleWidth: 'col-6',
  className: '',
};

export default PageHeader;
