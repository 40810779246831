import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
} from 'react-bootstrap';
import { datadogRum } from '@datadog/browser-rum';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './EmailAnnoucementPage.css';

import PageHeader from '../../components/PageHeader/PageHeader';
import { SNSTopic } from '../../controllers/user-service/types';
import { postAnnouncement } from '../../controllers/user-service';
import { AsyncState, MessageMap } from '../../utils/webRequests.type';
import SnackBar, { AlertTypes } from '../../components/snackBar/snackBar';

export default function EmailAnnoucementPage() {
  const [recipentType, setRecipentType] = useState<SNSTopic>('internal');
  const [subjectLine, setSubjectLine] = useState<string>('');
  const [emailContent, setEmailContent] = useState<string>('');
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [announcementStatus, setAnnouncementStatus] = useState<AsyncState>('uninitialized');
  const [announcementMessage, setAnnouncementMessage] = useState<MessageMap>({
    show: false,
    message: '',
    alertType: 'info',
  });
  const filesRef = useRef(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const { files } = (filesRef.current as unknown as HTMLInputElement);
    setAnnouncementStatus('loading');
    postAnnouncement(recipentType, subjectLine, emailContent)
      .then(() => {
        setSubjectLine('');
        setEditorState(EditorState.createEmpty());
        setRecipentType('internal');
        setAnnouncementStatus('completed');
        setAnnouncementMessage({
          show: true,
          message: 'Announcement has been sent Successfully',
          alertType: 'success',
        });
      })
      .catch((error) => {
        datadogRum.addError(error);
        setAnnouncementStatus('failed');
        setAnnouncementMessage({
          show: true,
          message: error.message,
          alertType: 'danger',
        });
      });
  };

  useEffect(() => {
    setEmailContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  useEffect(() => {
    if (announcementStatus !== 'loading') {
      setAnnouncementStatus('uninitialized');
    }
  }, [editorState, subjectLine]);

  const isContentValid = emailContent.trim() !== '<p></p>';
  return (
    <div
      id="email-announcement-page"
      className="page email-announcement-page h-100 col-12"
    >
      <SnackBar
        show={announcementMessage.show}
        alertText={announcementMessage.message}
        alertType={announcementMessage.alertType as AlertTypes}
        onClose={() => setAnnouncementMessage({ show: false, message: '', alertType: 'danger' })}
        header={announcementMessage.alertType === 'danger' ? 'Error' : 'Success'}
        style={{
          position: 'absolute',
          width: '40vw',
          zIndex: 1000,
          left: '30vw',
          top: '3rem',
        }}
      />
      <PageHeader title="Send Announcement" returnText="Back to Home" returnLink="/" />
      <div className="PageContent row">
        <div className="h-100 col-3">
          <strong>Select Recipients</strong>
          <Form.Check
            type="radio"
            id="recipents-radio-button"
            name="recipents-radio-button"
            label="All Users"
            title="test"
            onChange={() => setRecipentType('notifications')}
            checked={recipentType === 'notifications'}
          />
          <Form.Check.Label
            htmlFor="recipents-radio-button"
            className="muted ml-1 recipient-type-sub-label"
            onClick={() => setRecipentType('notifications')}
          >
            All active users of the application
          </Form.Check.Label>
          <Form.Check
            type="radio"
            name="recipents-radio-button"
            label="MAI Users"
            onChange={() => setRecipentType('internal')}
            checked={recipentType === 'internal'}
          />
          <Form.Check.Label
            htmlFor="recipents-radio-button"
            className="muted ml-1 recipient-type-sub-label"
            onClick={() => setRecipentType('internal')}
          >
            All users with an @marshallassoc.com email
          </Form.Check.Label>
        </div>
        <main className="col-9 h-100">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-1">
              <Form.Label><strong>Subject:</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the subject here"
                onChange={(e) => setSubjectLine(e.currentTarget.value)}
                value={subjectLine}
                isInvalid={!subjectLine}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label><strong>Body:</strong></Form.Label>
              <Editor
                toolbarClassName="wysiwyg-editor-toolbar"
                wrapperClassName="wysiwyg-editor-wrapper"
                editorClassName="wysiwyg-editor"
                editorState={editorState}
                placeholder="Enter your announcement here"
                onEditorStateChange={(newState: EditorState) => setEditorState(newState)}
              />
            </Form.Group>
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Control
                type="file"
                size="sm"
                ref={filesRef}
                multiple
              />
            </Form.Group>
            <Button
              type="submit"
              className="ma-btn send-announce-button"
              data-testid="send-announce-button"
              disabled={!subjectLine || !isContentValid || ['failed', 'loading'].includes(announcementStatus)}
            >
              Send Announcement
            </Button>
          </Form>
        </main>
      </div>
    </div>
  );
}
