import React from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import './NotFound.css';

function NotFound() {
  const location = useLocation();

  const lowerCasePath = location.pathname.toLocaleLowerCase();
  const isOldUrl = lowerCasePath.startsWith('/insights')
    || lowerCasePath.startsWith('/share_of_voice');
  return (
    <div className="page page-not-authorized">
      <PageHeader title="404: Not Found" returnText="Back to Home" returnLink="/" />
      <div className="error-message-container">
        {
          isOldUrl ? (
            <p>
              It looks like you are using an old link or bookmark.
              <br />
              Please delete any bookmarks that were created for
              {' '}
              <span className="text-blushing-salmon">www.marshallinsights.com</span>
              {' '}
              as they are no longer valid.
            </p>
          ) : (
            <p>
              This URL is invalid.
            </p>
          )
        }
        <p>
          Click
          {' '}
          <a className="ma-link" href="/">here</a>
          {' '}
          to go to the homepage.
        </p>
      </div>
    </div>
  );
}

export default NotFound;
