import React from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingPage.css';

type LoadingPageProps = {
  className?: string,
};

export default function LoadingPage(props: LoadingPageProps) {
  const { className } = props;
  return (
    <div
      id="loading-page"
      data-testid="loading-page"
      className={`Page container text-center mx-100 ${className}`}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

LoadingPage.defaultProps = {
  className: '',
};
