import React from 'react';
import { useSelector } from 'react-redux';
import {
  Col,
  Container, Row,
} from 'react-bootstrap';
import './Home.css';
import { selectProfileStatus } from '../../reducers/profile/profileSlice';
import LoadingPage from '../LoadingPage/LoadingPage';

export default function Home() {
  const currentProfileStatus = useSelector(selectProfileStatus);

  return (currentProfileStatus !== 'completed')
    ? (
      <LoadingPage />
    )
    : (
      <Container className="my-auto h-75">
        <Row className="my-5">
          <Col className="d-flex justify-content-center">
            <h3>Welcome to the Insights Management Application</h3>
          </Col>
        </Row>
        <Row className="my-5">
          <Col className="d-flex justify-content-center">
            <img src="/assets/images/Marshall_logo_insights.svg" alt="Marshall Insights Logo" style={{ width: '50%' }} />
          </Col>
        </Row>
      </Container>
    );
}
