export const headers = (orgID = 0) => {
  const result: Record<string, string> = {
    Authorization: `Bearer ${localStorage.getItem('user_service_token')}`,
    'MAI-ORGANIZATION-ID': String(orgID),
  };
  return result;
};

export default {
  headers,
};
