import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { datadogRum } from '@datadog/browser-rum';
import { RootState } from '../../app/store'; // eslint-disable-line import/no-cycle
import {
  isCognitoLoggedin,
  getTokenValues,
} from '../../controllers/auth';
import {
  DecodedUserServiceToken,
} from '../../controllers/auth/types';
import { AsyncState } from '../../utils/webRequests.type';

export const initData: DecodedUserServiceToken = {
  user_id: '000000-0000-0000-0000-000000',
  authentication_id: '000000-0000-0000-0000-000000',
  default_organization_id: 9000,
  email: '',
  first_name: 'f_name',
  last_name: 'l_name',
  grants: {
  },
  exp: 0,
};

export type ProfileState = {
  status: AsyncState,
  data: DecodedUserServiceToken,
};

const initialState: ProfileState = {
  status: 'uninitialized',
  data: initData,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const updateProfile = createAsyncThunk(
  'profile/getUserTokenValues',
  async () => {
    if (isCognitoLoggedin()) {
      return getTokenValues();
    }
    datadogRum.addError('updateProfile: failed to decode user token');
    throw Error('failed to decode user token');
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        if (action.payload != null) {
          state.status = 'completed';
          state.data = {
            ...state.data,
            ...action.payload,
          };
        } else {
          state.status = 'failed';
          state.data = initialState.data;
        }
      })
      .addCase(updateProfile.rejected, (state) => {
        state.status = 'failed';
        state.data = initialState.data;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProfileData = (state: RootState) => state.profile.data;
export const selectProfileStatus = (state: RootState) => state.profile.status;
export default profileSlice.reducer;
