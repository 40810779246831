import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Spinner,
} from 'react-bootstrap';
import {
  loadOrganizationResources,
  selectResourceStatus,
  loadOrganizationData,
  resetOrganizationData,
  selectOrganizationData,
  selectOwnerEmails,
  selectOrganizationStatus,
  setOrganizationRequestedAction,
  selectSubscribedRetailerList,
  selectSubscribedViewList,
} from '../../reducers/orgAdmin/orgAdminSlice';
import './OrgAdmin.css';
import {
  validateEmail,
} from '../../controllers/auth';
import {
  UserGrant,
} from '../../controllers/auth/types';
import {
  createUser,
  fetchUserByEmail,
  patchOrganization,
  patchUser,
} from '../../controllers/user-service';
import {
  OrganizationStatus,
  UpdateOrganization,
} from '../../controllers/user-service/types';
import PageHeader from '../../components/PageHeader/PageHeader';
import SubscriptionsSwitchBoard from '../../components/SubscriptionsSwitchBoard/SubscriptionsSwitchBoard';
import ViewsSwitchBoard from '../../components/ViewsSwitchBoard/ViewsSwitchBoard';
import { disableAllOrganizationSubscriptions } from '../../controllers/subscription-service';
import { deleteEmailReportServiceOrganizationLegacyReports } from '../../controllers/email-report-service';
import LoadingPage from '../LoadingPage/LoadingPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import noSpaces from '../../utils/noSpaces';
import { AsyncState } from '../../utils/webRequests.type';
import NotFound from '../NotFound/NotFound';
import { AppDispatch } from '../../app/store';

export default function OrgAdmin() {
  const dispatch = useDispatch<AppDispatch>();
  const resourceStatus = useSelector(selectResourceStatus);
  const initialOwnerEmails = useSelector(selectOwnerEmails);
  const [orgOwnerEmails, setOrgOwnerEmails] = useState<string>('');
  const [orgStatus, setOrgStatus] = useState<OrganizationStatus>('active');
  const [orgStatusChangeState, setOrgstatusChangeState] = useState<AsyncState>('completed');
  const [orgOwnerChangeState, setOrgOwnerChangeState] = useState<AsyncState>('completed');
  const subscribedRetailerList = useSelector(selectSubscribedRetailerList);
  const subscribedViewList = useSelector(selectSubscribedViewList);
  const selectedOrganization = useSelector(selectOrganizationData);
  const organizationLoadStatus = useSelector(selectOrganizationStatus);

  const { orgId } = useParams();

  const orgIdNumber = parseInt((orgId ?? '-1'), 10);

  useEffect(() => {
    // load organizationResources (one time load)
    dispatch(loadOrganizationResources());

    // reset organizationData on unmount.
    return () => { dispatch(resetOrganizationData()); };
  }, []);

  // then load the organization info
  useEffect(() => {
    if (orgId) {
      dispatch(loadOrganizationData(orgIdNumber));
    }
  }, [orgId]);

  useEffect(() => {
    if (organizationLoadStatus === 'completed') {
      setOrgOwnerEmails(initialOwnerEmails.join('\n'));
      setOrgStatus(selectedOrganization.status);
      if (window.history.pushState) {
        const newUrl = `${window.location.protocol}//${window.location.host}/orgAdmin/${String(selectedOrganization.id)}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        document.title = `Manage Organization: ${selectedOrganization.name}`;
      }
    }
  }, [organizationLoadStatus]);

  if (orgId === undefined) {
    return <NotFound />;
  }

  if (
    [
      organizationLoadStatus,
      orgOwnerChangeState,
      orgStatusChangeState,
      resourceStatus,
    ].some((status) => status === 'failed')
  ) {
    return <ErrorPage />;
  }

  const loadCompleted = [
    organizationLoadStatus,
    resourceStatus,
  ].every((status) => status === 'completed');

  if (!loadCompleted) {
    return (
      <div>
        <LoadingPage />
        ;
      </div>
    );
  }
  const handleOnChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgOwnerEmails(e.currentTarget.value);
  };

  const saveOrganizationOwnersEmailDisabled = (
    orgOwnerChangeState === 'loading'
    || orgOwnerEmails === initialOwnerEmails.join('\n') // emails haven't changed
    || orgOwnerEmails?.trim().length === 0 // there is not at least 1 owner
    || (orgOwnerEmails ?? '').split('\n').some( // there is at least 1 invalid email
      (s) => !validateEmail(s.trim()),
    )
  );

  const saveOrganizationOwnersSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();
    const orgOwnerEmailList = [...new Set((orgOwnerEmails ?? '').split('\n').map(
      (s) => s.trim(),
    )).values()];

    const incomingOwners: string[] = orgOwnerEmailList.filter(
      (s) => !initialOwnerEmails.includes(s),
    );
    const outgoingOwners: string[] = initialOwnerEmails.filter(
      (s) => !orgOwnerEmailList.includes(s),
    );
    const incomingOrgOwnersPromises = incomingOwners.map(
      async (email) => {
        if (email > '') {
          const response = await fetchUserByEmail(email);
          const userList = response.data;
          if (userList.length === 0) {
            const grants: { [organization_id: string]: UserGrant[] } = {
              [orgId]: ['ORG_OWNER', 'MEMBER'],
            };
            return createUser(
              selectedOrganization.id,
              {
                first_name: 'New',
                last_name: 'User',
                default_organization_id: selectedOrganization.id,
                email,
                grants,
              },
            );
          }
          const grants: { [organization_id: string]: UserGrant[] } = {
            ...userList[0].grants,
            [orgId]: ['ORG_OWNER', 'MEMBER'],
          };
          return patchUser(
            userList[0].id,
            selectedOrganization.id,
            { grants },
          );
        }
        return null;
      },
    );

    const outgoingOwnersPromises = outgoingOwners.map(
      async (email: string) => {
        if (email > '') {
          const response = await fetchUserByEmail(email);
          const userList = response.data;
          if (userList.length > 0) {
            const grants: { [organization_id: string]: UserGrant[] } = {
              ...userList[0].grants,
              [orgId]: ['MEMBER'],
            };
            return patchUser(
              userList[0].id,
              selectedOrganization.id,
              { grants },
            );
          }
        }
        return null;
      },
    );
    await Promise.allSettled(
      [
        ...incomingOrgOwnersPromises,
        ...outgoingOwnersPromises,
      ],
    ).catch(
      (e) => console.error(e), // eslint-disable-line no-console
    ).finally(
      () => {
        setOrgOwnerChangeState('completed');
        dispatch(loadOrganizationData(selectedOrganization.id));
      },
    );
  };

  const handleOnChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (['active', 'trial', 'dev', 'disabled', 'dead'].includes(e.currentTarget.value)) {
      setOrgStatus(e.currentTarget.value as OrganizationStatus);
    }
  };

  const saveOrganizationStatusDisabled = (
    orgStatusChangeState === 'loading'
    || selectedOrganization.status === orgStatus
  );

  const saveOrganizationStatus: React.FormEventHandler = async (event) => {
    event.preventDefault();
    setOrgstatusChangeState('loading');
    const payloadObject: UpdateOrganization = {
      name: selectedOrganization.name,
      status: orgStatus as OrganizationStatus,
      modules: selectedOrganization.modules,
    };
    await patchOrganization(selectedOrganization.id, payloadObject)
      .then(async () => {
        if (['disabled', 'dead'].includes(orgStatus)) {
          await disableAllOrganizationSubscriptions(selectedOrganization.id)
            .then(() => deleteEmailReportServiceOrganizationLegacyReports(selectedOrganization.id))
            .then(() => dispatch(setOrganizationRequestedAction('removeAllViews')));
        } else {
          await dispatch(loadOrganizationData(selectedOrganization.id));
        }
        setOrgstatusChangeState('completed');
      })
      .catch(
        (e) => {
          console.error(e); // eslint-disable-line no-console
          setOrgstatusChangeState('failed');
        },
      );
  };

  return (
    <div
      id="organization-administration"
      className="page h-100"
    >
      <PageHeader
        className="position-fixed"
        title={(
          <div className="text-truncate col-5">
            Manage Organization:
            <br />
            {selectedOrganization.name}
          </div>
        )}
        returnText="Back to Home"
        returnLink="/"
      />
      <Form
        className="h-100 col-12"
      >
        <div className="PageContent">
          <div className="col-2 h-100 info-pane position-fixed">
            <div className="pane">
              <Form.Label htmlFor="org-status">
                <strong>Status:</strong>
              </Form.Label>
              <Form.Select
                id="organization-status-select"
                data-testid="organization-status-select"
                aria-label="Organization Status"
                onChange={handleOnChangeStatus}
                value={orgStatus}
              >
                <option value="">(Select a status)</option>
                <option value="active">Active</option>
                <option value="trial">Trial</option>
                <option value="dev">Development</option>
                <option value="disabled">Disabled</option>
                <option value="dead">Dead</option>
              </Form.Select>
              <Button
                id="save-organization-status-button"
                data-testid="save-organization-status-button"
                className="col-12"
                variant="primary"
                onClick={saveOrganizationStatus}
                disabled={saveOrganizationStatusDisabled}
              >
                {orgStatusChangeState === 'loading' ? <Spinner data-testid="login-spinner" size="sm" animation="grow" /> : 'Update Status'}
              </Button>
            </div>
            <div className="pane">
              <Form.Label htmlFor="org-owner-email">
                <strong>Owners Email(s):</strong>
              </Form.Label>
              <Form.Control
                id="org-owner-email"
                data-testid="org-owner-email"
                type="email"
                as="textarea"
                value={orgOwnerEmails}
                placeholder="Enter Owner's email(s)"
                onChange={handleOnChangeEmail}
              />
              <span
                className="text-secondary"
              >
                Email addresses should be separated by a new line
              </span>
              <br />
              <Button
                id="update-org-owner-button"
                data-testid="update-org-owner-button"
                className="col-12"
                variant="primary"
                onClick={saveOrganizationOwnersSubmit}
                disabled={saveOrganizationOwnersEmailDisabled}
              >
                {orgOwnerChangeState === 'loading' ? <Spinner data-testid="login-spinner" size="sm" animation="grow" /> : 'Update Owners'}
              </Button>
            </div>
            <div className="pane">
              <a
                href="#subscription-switchboard"
                className="ma-link text-black"
              >
                <strong>Tracked Retailers:</strong>
              </a>
              <Card
                className="allow-y-scroll w-100 subscription-link-filters border-rounded"
              >
                {
                  subscribedRetailerList.map((retailerName) => {
                    const noSpaceRetailerName = noSpaces(retailerName);
                    return (
                      <a
                        id={`tracked-retailer-button-${noSpaceRetailerName}`}
                        data-testid={`tracked-retailer-button-${noSpaceRetailerName}`}
                        className="d-block ms-2 ma-link"
                        key={`retailerlist-${noSpaceRetailerName}`}
                        href={`#retailer-${noSpaceRetailerName}`}
                      >
                        {retailerName}
                      </a>
                    );
                  })
                }
              </Card>
            </div>
            <div className="pane">
              <a
                href="#view-switchboard"
                className="ma-link text-black"
              >
                <strong>Dashboard Views:</strong>
              </a>
              <Card
                className="allow-y-scroll w-100 subscription-link-filters border-rounded"
              >
                {subscribedViewList.map((viewName) => {
                  const noSpaceViewName = noSpaces(viewName);
                  return (
                    <a
                      id={`tracked-view-button-${noSpaceViewName}`}
                      data-testid={`tracked-view-button-${noSpaceViewName}`}
                      className="d-block ms-2 ma-link"
                      key={`viewlist-${noSpaceViewName}`}
                      href={`#view-${noSpaceViewName}`}
                    >
                      {viewName}
                    </a>
                  );
                })}
              </Card>
            </div>
          </div>
          <div className="permisions-page-container col-9 h-100 pull-right">
            <SubscriptionsSwitchBoard />
            <ViewsSwitchBoard />
          </div>
        </div>
      </Form>
    </div>
  );
}
