import React from 'react';
import {
  Navbar, Container, Nav, NavDropdown,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TriangleDown } from '../../icons';
import {
  selectProfileData,
  // selectProfileStatus,
} from '../../reducers/profile/profileSlice';
import './Navbar.css';
import { isCognitoLoggedin } from '../../controllers/auth';
import SelectOrganizationModal from '../SelectOrganizationModal/SelectOrganizationModal';
import CreateOrgModal from '../CreateOrgModal/CreateOrgModal';

const NavBar: React.FC = function NavBar() {
  // const profileStatus = useSelector(selectProfileStatus);
  const profile = useSelector(selectProfileData);

  const location = useLocation();

  const navigate = useNavigate();

  const loggedIn = isCognitoLoggedin();

  const navBarLogo = (
    <Navbar.Brand href="/"><img id="navbar-logo" src="/assets/images/insights-logo.svg" alt="marshall-logo" /></Navbar.Brand>
  );

  if (!loggedIn || location.pathname === '/login') {
    return (
      <Navbar className="navbar-container black-background" expand="lg">
        <Container>
          {navBarLogo}
        </Container>
      </Navbar>
    );
  }

  const toolsTitle = (
    <>
      <span className="nav-name-box">
        Tools
      </span>
      <TriangleDown className="icon-angle-down" />
    </>
  );

  const userDropDownTitle = (
    <>
      <span className="nav-name-box">
        {profile?.first_name}
        {' '}
        {profile?.last_name}
      </span>
      <TriangleDown className="icon-angle-down" />
    </>
  );

  const handleNavLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const { href } = event.currentTarget;
    const { pathname } = new URL(href);
    navigate(pathname);
  };

  return (
    <Navbar
      className="navbar-container black-background"
      expand="lg"
      onSubmit={(e) => e.preventDefault()}
    >
      <Container>
        {navBarLogo}
        <Nav className="me-auto white-text">
          <div id="user-info-area" className="container">
            <div
              className="stealth-text"
            >
              <i className="pe-7s-accordion icon-content" />
            </div>
            <NavDropdown
              className="user-info-item user-name-dropdown user-info-dropdown btn"
              title={toolsTitle}
              id="tools-dropdown"
              drop="down"
            >
              <CreateOrgModal />
              <SelectOrganizationModal />
              <NavDropdown.Item
                href="/notificationAdmin"
                data-testid="linkNotificationAdmin"
                onClick={handleNavLinkClick}
              >
                <p>Manage Notifications</p>
                <p className="tooltip-color">
                  Manage notifications
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/notificationCreate"
                data-testid="linkNotificationCreate"
                onClick={handleNavLinkClick}
              >
                <p>Create Notifications</p>
                <p className="tooltip-color">
                  Create notifications
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/sendAnnoucement"
                data-testid="linkSendAnnouncement"
                onClick={handleNavLinkClick}
              >
                <p>Send Announcement</p>
                <p className="tooltip-color">
                  Send an email blast
                </p>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className="user-info-item user-name-dropdown user-info-dropdown btn"
              title={userDropDownTitle}
              id="account-nav-dropdown"
              drop="down"
            >
              <NavDropdown.Item
                href="/logout"
                onClick={handleNavLinkClick}
              >

                <p>Logout</p>
                <p className="tooltip-color">
                  Sign out from your user profile and return to the login page
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavBar;
