/* istanbul ignore file */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { persistor, store } from './app/store';
import GlobalConfig, { DynamicConfig, fetchConfig } from './app/config';
import { initializeEmailReportServiceController } from './controllers/email-report-service';
import { initializeMaintenanceController } from './controllers/maintenance-service';
import { initializeProductController } from './controllers/product-service';
import { initializeSubscriptionController } from './controllers/subscription-service';
import { initializeUserController } from './controllers/user-service';
import MasterRoutes from './pages';
import './index.scss';
import './fonts/Pe-icon-7-stroke.scss';

fetchConfig()
  .then(() => {
    // initialize all modules dependent on config here
    initializeEmailReportServiceController(GlobalConfig.config.EMAIL_REPORT_SVC_URL);
    initializeMaintenanceController(GlobalConfig.config.MAINTENANCE_SVC_URL);
    initializeProductController(GlobalConfig.config.PRODUCT_SVC_URL);
    initializeSubscriptionController(GlobalConfig.config.SUBSCRIPTION_SVC_URL);
    initializeUserController(GlobalConfig.config.USER_SVC_URL);
  })
  .then(() => {
    if (['production', 'development'].includes(GlobalConfig.config.ENV)) {
      const tracedUrlKeys: (keyof DynamicConfig)[] = [
        'EMAIL_REPORT_SVC_URL',
        'MAINTENANCE_SVC_URL',
        'PRODUCT_SVC_URL',
        'SUBSCRIPTION_SVC_URL',
        'USER_SVC_URL',
      ];
      const excludedUrlKeys: (keyof DynamicConfig)[] = [];

      const allowedTracingUrls = tracedUrlKeys.map((key) => GlobalConfig.config[key])
        .filter((value) => value !== undefined);

      const excludedActivityUrls = excludedUrlKeys.map((key) => GlobalConfig.config[key])
        .filter((value) => value !== undefined);

      datadogRum.init({
        actionNameAttribute: 'data-custom-name',
        allowedTracingUrls,
        applicationId: GlobalConfig.config.DD_APP_ID,
        clientToken: GlobalConfig.config.REACT_APP_RUM_CLIENT_TOKEN,
        defaultPrivacyLevel: 'mask-user-input',
        excludedActivityUrls,
        site: 'datadoghq.com',
        service: 'management-app',
        env: GlobalConfig.config.ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        startSessionReplayRecordingManually: true,
        telemetrySampleRate: 0,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        version: GlobalConfig.config.REACT_APP_VERSION,
      });
    }
  })
  .then(() => {
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <MasterRoutes />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
    );
  });
