import {
  RetailerType,
  fetchRetailers,
} from '../../controllers/product-service';
import { AsyncState } from '../../utils/webRequests.type';

export function getRetailerNameById(
  retailerMap: { [key: string]: string },
  retailerName: string,
) {
  return Object.keys(retailerMap).find(
    (key) => (retailerMap[key] === retailerName),
  );
}

const loadRetailerList = async (
  retailerLoadStatus: AsyncState,
  setRetailerLoadStatus: (data: (AsyncState)) => void,
  setRetailerMap: (data: { [key: string]: string }) => void,
) => {
  if (retailerLoadStatus !== 'uninitialized') {
    return;
  }
  setRetailerLoadStatus('loading');
  try {
    const response = await fetchRetailers();
    const buildList: RetailerType[] = response.data ?? [];
    const selectMap: { [key: string]: string } = {};
    buildList
      .forEach(
        (r) => {
          selectMap[r.name] = r.id;
        },
      );
    setRetailerMap(selectMap);
    setRetailerLoadStatus('completed');
  } catch {
    setRetailerLoadStatus('failed');
    setRetailerMap({});
  }
};

export default loadRetailerList;
