/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
} from 'react-bootstrap';
import './NotificationCreate.css';
import LoadingPage from '../LoadingPage/LoadingPage';
import SnackBar, { AlertTypes } from '../../components/snackBar/snackBar';
import PageHeader from '../../components/PageHeader/PageHeader';
import loadRetailerList, { getRetailerNameById } from './retailerLoader';
import loadOfferedSubscriptionList from './offeredSubscriptionListLoader';
import loadMasterViewList from './masterViewLoader';
import LeftFilterSelect from '../../components/LeftFilterSelect/LeftFilterSelect';
import {
  postIssues,
} from '../../controllers/maintenance-service';
import {
  HelpRequest,
  IssueDetailType,
  IssueMultipleCreateType,
  NotificationDetail,
  NotificationType,
  OutageDetails,
  ProductNotificationDetails,
} from '../../controllers/maintenance-service/types';
import { AsyncState } from '../../utils/webRequests.type';
import { fetchOrganizationSubscriptions } from '../../controllers/subscription-service';
import { OrganizationSubscription, SubscriptionType } from '../../controllers/subscription-service/types';
import { LeftNavView } from '../../utils/masterViews';
import {
  messageTemplates,
  englishToCollectionTypeMap,
  collectionTypeToEnglishMap,
  NotificationTemplateType,
} from './messageTemplates';
import filterSubscriptions, { FilterSubscriptionsRequestedResultType } from './filterSubscriptions';
import generateMessages from './generateMessages';
import updateListAndFilter from './utilities';
import noSpaces from '../../utils/noSpaces';
import loadFullOrganizationList from './fullOrganizationListLoader';
import { fetchOrganizationsByView } from '../../controllers/user-service';
import { OrganizationType } from '../../controllers/user-service/types';

type NotificationCreateResultResponse = {
  status: number,
  expectedCount: number,
  actualCount: number,
  details: (
    OutageDetails
    | ProductNotificationDetails
    | HelpRequest
    | NotificationDetail
    | undefined
  ),
};

export default function NotificationAdmin() {
  // base resources to help populate / translate filter lists.
  const [retailerMap, setRetailerMap] = useState<{ [key: string]: string }>({});
  const [retailerLoadStatus, setRetailerLoadStatus] = useState<AsyncState>('uninitialized');
  const [offeredSubscriptionList, setOfferedSubscriptionList] = useState<SubscriptionType[]>([]);
  const [offeredSubscriptionLoadStatus, setOfferedSubscriptionLoadStatus] = useState<AsyncState>('uninitialized');
  const [masterViewList, setMasterViewList] = useState<LeftNavView[]>([]);
  const [masterViewLoadStatus, setMasterViewLoadStatus] = useState<AsyncState>('uninitialized');

  // actual lists of filter options.
  const [retailerList, setRetailerList] = useState<string[]>([]);
  const [collectionTypeList, setCollectionTypeList] = useState<string[]>([]);
  const [featureList, setFeatureList] = useState<string[]>([]);

  // the filters themselves
  const [retailerFilter, setRetailerFilter] = useState<string[]>([]);
  const [collectionTypeFilter, setCollectionTypeFilter] = useState<string[]>([]);
  const [featureListFilter, setFeatureFilter] = useState<string[]>([]);

  // templates for notifiation messages
  const [selectedTemplate, setSelectedTemplate] = useState<NotificationTemplateType | null>(null);

  // notification messages
  const [newNotifications, setNewNotifications] = useState<NotificationType[]>([]);
  const [
    createNewNotificationsDisabled,
    setCreateNewNotificationsDisabled,
  ] = useState<boolean>(true);
  const [previousNotifications, setPreviousNotifications] = useState<NotificationType | null>(null);

  // or SnackBar modal: displaying results after a submission to create notifications
  const [messageMap, setMessageMap] = useState({ show: false, alertType: 'success', alertText: '' });

  // populates the previewed messages with the correct template
  // and replaces text appropriately.
  const populateMessage = (m: NotificationType) => {
    if (selectedTemplate === null) {
      return m;
    }
    const newNotification: NotificationType = { ...m };

    let message = selectedTemplate.template;
    if (message.includes('<retailer_name>') && typeof m.retailerName !== 'undefined') {
      message = message.replace('<retailer_name>', m.retailerName);
    }
    if (message.includes('<collection_type>') && typeof m.collectionTypeEnglish !== 'undefined') {
      message = message.replace('<collection_type>', m.collectionTypeEnglish);
    }
    if (message.includes('<feature_name>') && typeof m.featureName !== 'undefined') {
      message = message.replace('<feature_name>', m.featureName);
    }
    newNotification.message = message;
    return newNotification;
  };

  // a little sugar to make this call prettier when called multiple times
  // in digestFilters()
  const callFilterSubscriptions = (
    requestedResult: FilterSubscriptionsRequestedResultType,
  ) => filterSubscriptions(
    offeredSubscriptionList,
    masterViewList,
    retailerMap,
    retailerFilter,
    collectionTypeFilter,
    featureListFilter,
    requestedResult,
  );

  const digestFilters = () => {
    const newRetailerDict: { [key: string]: boolean } = {};
    const newCollectionTypeDict: { [key: string]: boolean } = {};
    const newFeatureDict: { [key: string]: boolean } = {};

    callFilterSubscriptions('retailerName')
      .forEach(
        (s: SubscriptionType) => {
          newRetailerDict[getRetailerNameById(retailerMap, s.retailer_id) as string] = true;
        },
      );

    callFilterSubscriptions('collectionType')
      .forEach(
        (s: SubscriptionType) => {
          newCollectionTypeDict[
            collectionTypeToEnglishMap(s.collection_type)
          ] = true;
        },
      );

    callFilterSubscriptions('featureName')
      .forEach(
        (s: SubscriptionType) => {
          masterViewList
            .filter(
              (view: LeftNavView) => (
                view.required_collection_types.includes(s.collection_type)
              ),
            )
            .forEach(
              (view: LeftNavView) => {
                newFeatureDict[view.friendly_name] = true;
              },
            );
        },
      );

    updateListAndFilter(
      Object.keys(newRetailerDict).sort(),
      retailerList,
      setRetailerList,
      retailerFilter.filter(
        (s: string) => Object.keys(newRetailerDict).sort().includes(s),
      ),
      retailerFilter,
      setRetailerFilter,
    );

    updateListAndFilter(
      Object.keys(newCollectionTypeDict).sort(),
      collectionTypeList,
      setCollectionTypeList,
      collectionTypeFilter.filter(
        (s: string) => Object.keys(newCollectionTypeDict).sort().includes(s),
      ),
      collectionTypeFilter,
      setCollectionTypeFilter,
    );

    updateListAndFilter(
      Object.keys(newFeatureDict).sort(),
      featureList,
      setFeatureList,
      featureListFilter.filter(
        (s: string) => Object.keys(newFeatureDict).sort().includes(s),
      ),
      featureListFilter,
      setFeatureFilter,
    );
  };

  useEffect(() => {
    setNewNotifications(
      generateMessages(
        retailerFilter,
        retailerMap,
        collectionTypeFilter,
        englishToCollectionTypeMap,
        featureListFilter,
        masterViewList,
      ).map(
        (n: NotificationType) => (populateMessage(n) as NotificationType),
      ),
    );
  }, [
    retailerFilter,
    collectionTypeFilter,
    featureListFilter,
  ]);

  // load data
  useEffect(() => {
    if (retailerLoadStatus === 'uninitialized') {
      loadRetailerList(
        retailerLoadStatus,
        setRetailerLoadStatus,
        setRetailerMap,
      );
    }
    if (offeredSubscriptionLoadStatus === 'uninitialized') {
      loadOfferedSubscriptionList(
        offeredSubscriptionLoadStatus,
        setOfferedSubscriptionList,
        setOfferedSubscriptionLoadStatus,
      );
    }
    if (masterViewLoadStatus === 'uninitialized') {
      loadMasterViewList(
        masterViewLoadStatus,
        setMasterViewList,
        setMasterViewLoadStatus,
      );
    }
  }, []);

  useEffect(() => {
    if (
      retailerLoadStatus !== 'completed'
      || offeredSubscriptionLoadStatus !== 'completed'
      || masterViewLoadStatus !== 'completed'
    ) {
      return;
    }
    digestFilters();
  }, [
    retailerLoadStatus,
    offeredSubscriptionLoadStatus,
    masterViewLoadStatus,
    retailerFilter,
    collectionTypeFilter,
    featureListFilter,
  ]);

  // submits the previewed notifications to maintenance service
  const generateIssueNotification = async (n: NotificationType) => {
    // construct the payload, minus the organization_ids
    const details: OutageDetails = {};

    let type = '';
    if (typeof n.retailerId !== 'undefined' && n.retailerId !== null) {
      type = 'retailer_outage';
      details.retailer_name = n.retailerName;
    }
    if (typeof n.collectionType !== 'undefined' && n.collectionType !== null) {
      type = 'collection_outage';
      details.collection_type = n.collectionTypeEnglish;
    }
    if (typeof n.reportName !== 'undefined' && n.reportName !== null) {
      type = 'feature_outage';
      details.feature_name = n.featureName;
    }

    const payload: IssueMultipleCreateType = {
      org_id_list: [],
      issue_template: {
        service: 'management-app-notification',
        type: type as IssueDetailType,
        status: 'open',
        observed_timestamp: (new Date()).toISOString(),
        severity: 'informational',
        message: n.message,
        details,
      },
    };

    // need a list of all orgs
    let orgIdList = await loadFullOrganizationList() ?? [];

    // subscription results...
    if (
      (typeof n.retailerId !== 'undefined' && n.retailerId !== null)
      || (typeof n.collectionType !== 'undefined' && n.collectionType !== null)
    ) {
      let subscriptionResults: OrganizationSubscription[] = [];
      const querySubscriptionResponse = await fetchOrganizationSubscriptions(
        0,
        true,
        (
          typeof n.collectionType !== 'undefined'
          && n.collectionType !== null
        ) ? [n.collectionType] : undefined,
        (
          typeof n.retailerId !== 'undefined'
          && n.retailerId !== null
        ) ? [n.retailerId] : undefined,
      );
      if (Array.isArray(querySubscriptionResponse.data)) {
        subscriptionResults = querySubscriptionResponse.data;
      }
      orgIdList = (orgIdList ?? []).filter(
        (orgId) => subscriptionResults.map(
          (x: OrganizationSubscription) => Number(x.organization_id),
        ).includes(orgId),
      );
    }

    // feature results...
    if (typeof n.reportName !== 'undefined' && n.reportName !== null) {
      let featureResults: OrganizationType[] = [];
      const queryFeatureResponse = await fetchOrganizationsByView(n.reportName);
      if (Array.isArray(queryFeatureResponse.data)) {
        featureResults = queryFeatureResponse.data;
      }
      orgIdList = (orgIdList ?? []).filter(
        (orgId) => featureResults.map(
          (x: OrganizationType) => Number(x.id),
        ).includes(orgId),
      );
    }

    payload.org_id_list = orgIdList;

    const response = await postIssues(payload);

    // need to return the result.
    const resultObj: NotificationCreateResultResponse = {
      status: response.status,
      expectedCount: orgIdList.length,
      actualCount: response.data.length,
      details: payload.issue_template.details,
    };
    return resultObj;
  };

  // Only sends first request (should be a limit of 1 now)
  // does not try to loop or collect statistics
  const createNewIssueHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (newNotifications.length === 0) {
      return;
    }
    // This is to make a copy newNotifications to previousNotifications
    // (otherwise they will share the same reference)
    setPreviousNotifications({ ...newNotifications[0] });
    const result = await generateIssueNotification(newNotifications[0]);
    if (result.status !== 200 || result.expectedCount !== result.actualCount) {
      setMessageMap(
        {
          show: true,
          alertText: `Failure.  Not all notifications created for ${JSON.stringify(result.details as { [key: string]: string })}. Created ${result.actualCount} out of ${result.expectedCount} expected notification(s).`,
          alertType: 'danger',
        },
      );
    } else {
      setMessageMap(
        {
          show: true,
          alertText: `${result.actualCount} of ${result.expectedCount} notifications created.`,
          alertType: 'success',
        },
      );
    }
  };

  useEffect(() => {
    let disabled = false;
    if (
      typeof selectedTemplate === 'undefined'
      || selectedTemplate === null
    ) {
      disabled = true;
    }
    if ([
      ...retailerFilter,
      ...collectionTypeFilter,
      ...featureListFilter,
    ].length === 0) {
      disabled = true;
    }
    if (JSON.stringify(previousNotifications) === JSON.stringify(newNotifications[0])) {
      disabled = true;
    }
    setCreateNewNotificationsDisabled(disabled);
  }, [
    selectedTemplate,
    retailerFilter,
    collectionTypeFilter,
    featureListFilter,
    previousNotifications,
    newNotifications,
  ]);

  useEffect(() => {
    setNewNotifications(
      newNotifications.map(
        (n: NotificationType) => (populateMessage(n) as NotificationType),
      ),
    );
  }, [selectedTemplate]);

  const handleTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const tempTemplateList = messageTemplates.filter(
      (mt) => (mt.label === event.target.value),
    );
    setSelectedTemplate((tempTemplateList.length > 0) ? tempTemplateList[0] : null);
  };

  const updateMessage = (key: string, newMessage: string) => {
    const notifications: NotificationType[] = [];
    newNotifications.forEach(
      (n) => {
        if (n.key === key) {
          const m: NotificationType = { ...n };
          m.message = newMessage;
          notifications.push(m);
        } else {
          notifications.push(n);
        }
      },
    );
    setNewNotifications(notifications);
  };
  return (
    <div
      id="notification-create-page"
      className="page h-100"
    >
      {
        (
          retailerLoadStatus !== 'completed'
          || offeredSubscriptionLoadStatus !== 'completed'
          || masterViewLoadStatus !== 'completed'
        )
          ? <LoadingPage />
          : (
            <Form
              className="h-100 col-12"
            >
              <PageHeader
                title="Create Notifications"
                returnText="Back to Home"
                returnLink="/"
              />
              <div className="PageContent row">
                <div className="col-3 h-100 info-pane">
                  <h6>Select Tags:</h6>
                  {
                    retailerList.length > 0
                      ? (
                        <div className="pane">
                          <LeftFilterSelect
                            label="Retailer"
                            listLoadStatus="completed"
                            listData={retailerList}
                            selectedData={retailerFilter}
                            setSelectedData={setRetailerFilter}
                            maxOneSelection
                          />
                        </div>
                      )
                      : null
                  }
                  {
                    collectionTypeList.length > 0
                      ? (
                        <div className="pane">
                          <LeftFilterSelect
                            label="Collection Type"
                            listLoadStatus="completed"
                            listData={collectionTypeList}
                            selectedData={collectionTypeFilter}
                            setSelectedData={setCollectionTypeFilter}
                            maxOneSelection
                          />
                        </div>
                      )
                      : null
                  }
                  {
                    featureList.length > 0
                      ? (
                        <div className="pane">
                          <LeftFilterSelect
                            label="Feature"
                            listLoadStatus="completed"
                            listData={featureList}
                            selectedData={featureListFilter}
                            setSelectedData={setFeatureFilter}
                            maxOneSelection
                          />
                        </div>
                      )
                      : null
                  }
                </div>
                <div
                  className="col-9 h-100 container pull-right"
                >
                  <h6>Notification Preview:</h6>
                  <div className="row mb-3 notification-template-selector">
                    <div className="col-9">
                      <span className="template-select-label">
                        Message Type
                      </span>
                      <Form.Select
                        aria-label="Template Selection"
                        onChange={handleTemplateChange}
                        className="template-select"
                        data-testid="template-selector"
                      >
                        <option>Select a Template</option>
                        {
                          messageTemplates.map(
                            (mt) => (
                              <option
                                key={`template-${mt.label}`}
                                value={mt.label}
                                data-testid={`template-${noSpaces(mt.label)}`}
                              >
                                {mt.label}
                              </option>
                            ),
                          )
                        }
                      </Form.Select>
                    </div>
                    <div className="col-3 text-right">
                      <Button
                        variant="primary"
                        id="post-notifications"
                        data-testid="post-notifications"
                        disabled={createNewNotificationsDisabled}
                        onClick={createNewIssueHandler}
                      >
                        Post Notification(s)
                      </Button>
                    </div>
                  </div>
                  {
                    newNotifications.map(
                      (n: NotificationType) => (
                        <div
                          className="row mb-3"
                          key={n.key}
                        >
                          <span
                            className="notification-label"
                          >
                            Notification:
                          </span>
                          <Form.Control
                            as="textarea"
                            id={`message-textarea-${n.key}`}
                            className="notification-message"
                            data-testid={`message-textarea-${n.key}`}
                            placeholder="Enter your notification here."
                            value={n.message}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              updateMessage(n.key, e.currentTarget.value);
                            }}
                          />
                        </div>
                      ),
                    )
                  }
                </div>
              </div>
              <SnackBar
                show={messageMap.show}
                alertText={messageMap.alertText}
                alertType={messageMap.alertType as AlertTypes}
                onClose={() => setMessageMap({ show: false, alertText: '', alertType: 'success' })}
                header={messageMap.alertType === 'danger' ? 'Error' : 'Success'}
                style={{
                  position: 'absolute',
                  width: '40vw',
                  zIndex: 1000,
                  top: '10rem',
                  left: '30vw',
                }}
              />
            </Form>
          )
      }
    </div>
  );
}
