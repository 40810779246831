// eslint-disable @typescript-eslint/no-explicit-any
import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import Home from './Home/Home';
import Login from './Login/Login';
import Logout from './Logout/LogOut';
import OrgAdmin from './OrgAdmin/OrgAdmin';
import NotificationAdmin from './NotificationAdmin/NotificationAdmin';
import NotificationCreate from './NotificationCreate/NotificationCreate';
import ProtectedRoute from '../controllers/auth/protectedRoute';
import NotFound from './NotFound/NotFound';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotAuthorized from './NotAuthorized/NotAuthorized';
import NavBar from '../components/navbar/Navbar';
import Reset from './Login/Reset';
import Forgot from './Login/Forgot';
import ErrorPage from './ErrorPage/ErrorPage';
import NavigationMessage from '../components/NavigationMessage/NavigationMessage';
import EmailAnnoucementPage from './EmailAnnoucementPage/EmailAnnoucementPage';
import GlobalConfig from '../app/config';
import UnavailablePage from './UnavailablePage/UnavailablePage';
import QuicksightWarningSnackBar from '../components/quicksightWarningSnackBar/quicksightWarningSnackBar';
import useDataDog from '../hooks/useDataDog';

/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
export class PageErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    datadogRum.addError(error);
    console.error(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorPage />;
    }
    return children;
  }
}

/* istanbul ignore next */
// eslint-disable-next-line react/display-name
export default function MasterRoutes() {
  const isProd = GlobalConfig.config.ENV === 'production';
  useDataDog();
  return (
    <>
      <NavBar />
      <div className="under-nav" />
      <PageErrorBoundary>
        <NavigationMessage />
        <Routes>
          <Route
            path="/"
            element={(
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/sendAnnoucement"
            element={(
              <ProtectedRoute>
                {
                  isProd ? (<UnavailablePage />) : <EmailAnnoucementPage />
                }
              </ProtectedRoute>
            )}
          />
          <Route
            path="/login"
            element={(
              <Login />
            )}
          />
          <Route
            path="/forgot"
            element={(
              <Forgot />
            )}
          />
          <Route
            path="/reset"
            element={(
              <Reset />
            )}
          />
          <Route
            path="/logout"
            element={(
              <Logout />
            )}
          />
          <Route
            path="/error"
            element={(
              <ErrorPage />
            )}
          />
          <Route
            path="/NotFound"
            element={(
              <NotFound />
            )}
          />
          <Route
            path="/NotAuthorized"
            element={(
              <ProtectedRoute>
                <NotAuthorized />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/orgAdmin"
            element={(
              <ProtectedRoute requestedGrants={['APPLICATION_RESOURCE_MANAGER']}>
                <QuicksightWarningSnackBar />
                <OrgAdmin />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/orgAdmin/:orgId"
            element={(
              <ProtectedRoute requestedGrants={['APPLICATION_RESOURCE_MANAGER']}>
                <OrgAdmin />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/notificationAdmin/"
            element={(
              <ProtectedRoute requestedGrants={['APPLICATION_NOTIFIER']}>
                <NotificationAdmin />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/notificationCreate/"
            element={(
              <ProtectedRoute requestedGrants={['APPLICATION_NOTIFIER']}>
                <NotificationCreate />
              </ProtectedRoute>
            )}
          />
          <Route
            path="*"
            element={(
              <ProtectedRoute>
                <NotFound />
              </ProtectedRoute>
            )}
          />
        </Routes>
      </PageErrorBoundary>
    </>
  );
}
